import React, { useMemo, useState, useEffect } from 'react';
import { Table, Button, Popconfirm, DatePicker, message, Tooltip, Select, Checkbox, TimePicker, Modal } from 'antd';
import '../GuideAddTourForm.css';

import { Container, Row, Col, Form } from 'react-bootstrap';
import ApiClient from './ApiClient';
import moment from 'moment';
import { TeamOutlined, DeleteOutlined, PlayCircleOutlined, PauseOutlined, CloseCircleOutlined, UserOutlined, DollarOutlined, SearchOutlined } from '@ant-design/icons';
import axios from 'axios';
import API_URL from '../config';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import withGuideAccess from './withGuideAccess';
import dayjs from 'dayjs';
const GuideTourScheduleForm = () => {

  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState('');
  const [tourDayFrom, setTourDayFrom] = useState('');
  const [tourDayTo, setTourDayTo] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [selectedTimeMany, setSelectedTimeMany] = useState('');
  const [selectedWeekDay, setSelectedWeekDay] = useState();
  const [toursData, setToursData] = useState([]);
  const [clientsData, setClientsData] = useState([]);
  const [dateFrom, setDateFrom] = useState(moment(new Date()));
  const [dateTo, setDateTo] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const { id } = useParams();
  const [arrow, setArrow] = useState('Show');
  const [addTourModalOpen, setAddTourModalOpen] = useState(false);
  const [addManyTourModalOpen, setAddManyTourModalOpen] = useState(false);
  const [bookedPersonsModalOpen, setBookedPersonsModalOpen] = useState(false);
  const [cancelTourModalOpen, setCancelTourModalOpen] = useState(false);
  const [tourEarningsTotal, setTourEarningsTotal] = useState(0); 
  const [tourEarnings, setTourEarnings] = useState(0);
  const [tourFees, setTourFees] = useState(0);
  const [tourPaidOut, setTourPaidOut] = useState(0);
  const [tourPayOutDate, setTourPayOutDate] = useState(null);
  const [selectedRecord, setSelectedRecord] = useState(null);



  const mergedArrow = useMemo(() => {
    if (arrow === 'Hide') {
      return false;
    }

    if (arrow === 'Show') {
      return true;
    }

    return {
      pointAtCenter: true,
    };
  }, [arrow]);

  useEffect(() => {

    setDateFrom(moment(new Date()));
    if (id) {
      fetchData();
    }

  }, [id]);



  const handleTimeChangeManyTours = (value) => {
    setSelectedTimeMany(value);
  };

  const handleTimeChange = (e) => {
    setSelectedTime(e);
  };
  const fetchData = async () => {

    let url = 'api/TourDefinitions/' + id + '/tours';

    if (dateFrom !== null && dateTo !== null) {
      let dateTimeFrom = moment(`${moment(new Date(dateFrom)).format('YYYY-MM-DD')} 00:00:00`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm:ss');
      let dateTimeTo = moment(`${moment(new Date(dateTo)).format('YYYY-MM-DD')} 23:59:59`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm:ss');

      url = url + '?dateFrom=' + dateTimeFrom + '&dateTo=' + dateTimeTo;
    } else
      if (dateFrom !== null) {
        let dateTimeFrom = moment(`${moment(new Date(dateFrom)).format('YYYY-MM-DD')} 00:00:00`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm:ss');


        url = url + '?dateFrom=' + dateTimeFrom;

      } else
        if (dateTo !== null) {

          let dateTimeTo = moment(`${moment(new Date(dateTo)).format('YYYY-MM-DD')} 23:59:59`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm:ss');

          url = url + '?dateTo=' + dateTimeTo;
        }

    const response = await ApiClient('GET', url);
    if (response.ok) {
      const data = await response.json();
      const toursData = data.map(item => {        
        return {
          ...item
        };
      });
      setToursData(toursData);
    }
  };
  const handleTimeBlur = (e) => {
    const timeValue = e.target.value;
    const isValidTime = /^([0-1]?[0-9]|2[0-3]?):?([0-5][0-9]?)?$/.test(timeValue);

    if (!isValidTime) {
      setSelectedTime('');
    }
  };
  const handleTimeBlurAddMany = (e) => {
    const timeValue = e.target.value;
    const isValidTime = /^([0-1]?[0-9]|2[0-3]?):?([0-5][0-9]?)?$/.test(timeValue);

    if (!isValidTime) {
      setSelectedTimeMany('');
    }
  };
  const handleShowClients = async (record) => {  


    setClientsData(record.tourClients);
    setTourEarnings(record.earnings);
    setTourPaidOut(record.paidOut);
    setTourFees(record.fees);
    setTourEarningsTotal(record.earningsTotal);
    setTourPayOutDate(record.dateOfPayOut)
    setBookedPersonsModalOpen(true);

  }
 
  const handleActivate = async (id) => {
    try {
      await axios.patch(API_URL + 'api/tourdefinitions/tours/' + id + '/activate', null, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });


      // Filtruj dane, aby usunąć rekord o określonym identyfikatorze
      await fetchData();
    } catch {
      message.error(t("message.error"));
    }

  };
  const handleCancelation = async () => {
    let x = selectedRecord.id ;
    
    try {
      await axios.patch(API_URL + 'api/tourdefinitions/' + selectedRecord.id + '/cancel', null, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });


      // Filtruj dane, aby usunąć rekord o określonym identyfikatorze
      await fetchData();
    } catch {
      message.error(t("message.error"));
    }
    // setSelectedRecord(null);
  };
  const handleDelete = async (id) => {
    try {
      await axios.delete(API_URL + 'api/tourdefinitions/tours/' + id, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });


      // Filtruj dane, aby usunąć rekord o określonym identyfikatorze
      await fetchData();
    } catch {
      message.error(t("message.error"));
    }

  };
  const handleIsActiveChange = (e) => {

    setIsActive(e.target.checked);
  };
  const onAddTourClick = () => {
    setAddTourModalOpen(true);

  }
  const handleCloseAddTourModal = () => {
    setAddTourModalOpen(false);
  };
  const onAddManyTourClick = () => {
    setAddManyTourModalOpen(true);

  }
 
  const handleCloseAddManyTourModal = () => {
    setAddManyTourModalOpen(false);
  };
  const handleClosePersonsBookedModal = () => {
    setBookedPersonsModalOpen(false);
  };
   const onCancelTourClick = (record) => {
    setSelectedRecord(record);
    setCancelTourModalOpen(true); 

  }
  const handleCloseCancelTourModal = () => {
    setCancelTourModalOpen(false);
  };
  const handleSearch = async () => {
    fetchData();
  }
  const mergeDateAndTime = (date, time) => {
    return dayjs(date)
      .hour(time.hour())
      .minute(time.minute())
      .second(time.second())
      .millisecond(time.millisecond());
  };

  const handleAddTour = async () => {
    if (selectedDate && selectedTime) {
      const dateTime = mergeDateAndTime(selectedDate,selectedTime);
     
      const createTourDto = {
        date: dateTime
      };
      await axios.post(API_URL + 'api/tourdefinitions/' + id + '/tours', createTourDto, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }).then(response => {
        // const newRecord = {
        //   id: response.data.id,
        //   date: response.data.date,
        // };

        // setToursData([...toursData, newRecord]);
        setSelectedDate(null);
        setSelectedTime('');
        setAddTourModalOpen(false);

        message.success(t("message.saveOk"));
        fetchData();
      })
        .catch(error => {
          message.error(t("message.error"));
        })
    }
  };
  const handleAddManyTour = async () => {
    if (selectedTimeMany && tourDayFrom && tourDayTo && selectedWeekDay) {

      // const selectedDayFrom = moment(`${moment(new Date(tourDayFrom)).format('YYYY-MM-DD')} 00:00`, 'YYYY-MM-DD HH:mm').format(
      //   'YYYY-MM-DDTHH:mm:ss');
      // const selectedDayTo = moment(`${moment(new Date(tourDayTo)).format('YYYY-MM-DD')} 23:59`, 'YYYY-MM-DD HH:mm').format(
      //   'YYYY-MM-DDTHH:mm:ss');
      // );

      const createTourDto = {
        DateFrom: tourDayFrom,
        DateTo: tourDayTo,
        Hour: selectedTimeMany,
        DayOfWeek: selectedWeekDay,
        isActive: isActive
      };
      await axios.post(API_URL + 'api/tourdefinitions/' + id + '/tours/multiple', createTourDto, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }).then(response => {


        setTourDayFrom(null);
        setIsActive(false);
        setTourDayTo(null);
        setSelectedTimeMany('');
        setAddManyTourModalOpen(false);
        message.success(t("message.saveOk"));
        fetchData();
      })
        .catch(error => {
          message.error(t("message.error"));
        })
    }
  };
  const selectWeekDayHandle = (value) => {
    setSelectedWeekDay(value);
  }
  
  const columnsClients = [
   
    {
      title: 'Nr',
      dataIndex: 'reservationNumber',
      key: 'reservationNumber',
      width: "130px",
      render: (text, record) => (
        <div>
          <div>{record.reservationNumber}</div>
          <div>{record.email}</div>
        </div>
      ),
    },
   
    {
      title: t("schedule.additionalInfo"),
      key: 'additionalInfo',
      render: (text, record) => (
        <div>
          <div>{t("schedule.price")}: {record.price.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {record.currency}</div>
          <div>{t("schedule.paid")}: {record.paidAmount.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {record.currency}</div>
          <div>{t("schedule.fees")}: {record.fee.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {record.currency}</div>
          <div>{t("schedule.topay")}: {(record.price - record.paidAmount).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {record.currency}</div>
          <div>{t("schedule.resignationAt")}: {record.resignationAt === null ? "" : moment(record.resignationAt).format('YYYY-MM-DD HH:mm:ss')}</div>
          <div>{t("schedule.persons")}: {renderPersonDetails(record.personsDetailsJson)}</div>
      
        </div>
      ),
    }, 


  ];
  const renderPersonDetails = (value) => {
    const dataArray = JSON.parse(value);
    return dataArray.map(item => `${item.Name} (${item.AgeFrom}-${item.AgeTo}) x ${item.Count}`).join(' , ');
  }

  const columns = [
    {
      title: t("schedule.date"),
      dataIndex: 'date',
      key: 'date',
      width: '100px',
      render: (date) => moment(date).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: (
        <span>

          <Tooltip title={t("schedule.clientsBooked")}>
            <UserOutlined style={{ marginLeft: 8 }} />
          </Tooltip>
        </span>
      ),
      dataIndex: 'clientsBooked',
      key: 'clientsBooked',
      default: 0
    },
   
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span>
            {record.isCanceled ? t("schedule.statusCancelled") :
              record.isActive
                ? t("schedule.active")
                : t("schedule.notactive")
            }
          </span>

        </div>
      ),

    },
    {
      title: t("schedule.actions"),
      key: 'actions',
      width: 150,
      render: (text, record) => (
        <div>

          <Popconfirm
            title={t('schedule.areYouSureActivate')}
            onConfirm={() => handleActivate(record.id)}
            okText={t('common.yes')}
            cancelText={t('common.no')}>
            {/* <Tooltip placement="top" title={t('schedule.ttActivate')} arrow={mergedArrow} mouseEnterDelay={0.8}> */}

              <Button
                type="link"
                icon={<PlayCircleOutlined />}
                style={{ color: 'blue', background: '#fff' }}
                hidden={record.isCanceled || record.isActive}
              />
            {/* </Tooltip> */}

          </Popconfirm>
          <Popconfirm
            title={t('schedule.areYouSureDeactivate')}
            onConfirm={() => handleActivate(record.id)}
            okText={t('common.yes')}
            cancelText={t('common.no')}>
            {/* <Tooltip placement="top" title={t('schedule.ttDeactivate')} arrow={mergedArrow} mouseEnterDelay={0.8}> */}

              <Button
                type="link"
                icon={<PauseOutlined />}
                style={{ color: 'blue', background: '#fff' }}
                hidden={record.isCanceled || !record.isActive || record.clientsBooked > 0}
              />
            {/* </Tooltip> */}

          </Popconfirm>

          <Button
            type="link"
            onClick={() => handleShowClients(record)}
            icon={<TeamOutlined />}
            style={{ color: 'blue', background: '#fff' }}
            hidden={record.clientsBooked === 0}
          />
          {/* <Button
            type="button"
            onClick={() => handleShowEarnings(record)}
            icon={<DollarOutlined />}
            style={{ color: 'blue', background: '#fff' }}

          /> */}
          <Button
                type="link"
                icon={<CloseCircleOutlined />}
                style={{ color: 'red', background: '#fff' }}
                hidden={record.isCanceled || !record.isActive || !record.isEditable || (record.isActive && record.clientsBooked === 0)}
                onClick={() => onCancelTourClick(record)}
              />
        
          {/* <Popconfirm
            title={t('schedule.areYouSureCancel')}
            onConfirm={() => handleCancelation(record.id)}
            okText={t('common.yes')}
            cancelText={t('common.no')}>
            <Tooltip placement="top" title={t('schedule.ttCancel')} arrow={mergedArrow} mouseEnterDelay={0.8}>

              <Button
                type="link"
                icon={<CloseCircleOutlined />}
                style={{ color: 'red', background: '#fff' }}
                hidden={record.isCanceled || !record.isActive || !record.isEditable || (record.isActive && record.clientsBooked === 0)}
              />
            </Tooltip>

          </Popconfirm> */}
         
          <Popconfirm
            title={t('common.areYouSureDelete')}
            onConfirm={() => handleDelete(record.id)}
            okText={t('common.yes')}
            cancelText={t('common.no')}>
            {/* <Tooltip placement="top" title={t('schedule.ttDelete')} arrow={mergedArrow} mouseEnterDelay={0.8}> */}
              <Button
                type="link"
                icon={<DeleteOutlined />}
                style={{ color: 'red', background: '#fff' }}
                hidden={record.clientsBooked > 0} />
            {/* </Tooltip> */}

          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <Container >
      <div className="center" style={{ marginTop: "10px" }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <DatePicker
            id="date-from"
            value={dateFrom}
            onChange={(date) => setDateFrom(date)}
            dateFormat="yyyy-MM-dd"
            placeholder={t("common.dateFrom")}
            style={{ marginRight: "5px", width: 120 }}
          />

          <DatePicker
            id="date-to"
            value={dateTo}
            onChange={(date) => setDateTo(date)}
            dateFormat="yyyy-MM-dd"
            placeholder={t("common.dateTo")}
            style={{ marginRight: "5px", width: 120 }}
          />

          <Button onClick={handleSearch}
            type="button"
            className="mt-1 mb-1 lg:mt-4"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '8px',
              backgroundColor: 'rgb(255, 152, 0)',
              color: 'rgb(17, 24, 39)',
              border: '2px solid rgb(255, 152, 0)'

            }}><SearchOutlined style={{ fontSize: '20px' }} /></Button>

        </div>
      </div>
      <div className="center">
        <div className="add-tour">
        <div className="center">
          <Button onClick={onAddTourClick}
            type="button"
            className="mt-1 mb-1 lg:mt-4"
            style={{
              borderRadius: '8px',
              backgroundColor: 'rgb(255, 152, 0)',
              color: 'rgb(17, 24, 39)',
              border: '2px solid rgb(255, 152, 0)'
            }}>{t("schedule.newTour")}</Button>
          <Button onClick={onAddManyTourClick}
            type="button"
            className="mt-1 mb-1 lg:mt-4"
            style={{
              borderRadius: '8px',
              backgroundColor: 'rgb(255, 152, 0)',
              color: 'rgb(17, 24, 39)',
              border: '2px solid rgb(255, 152, 0)',
              marginLeft: "11px"
            }}>{t("schedule.addManyTours")}</Button>
          </div>
          <div style={{marginTop:"10px"}}>

            <Table dataSource={toursData} columns={columns} size="small"
              pagination={{ defaultPageSize: 5 }}></Table>
          </div>



        </div>
       
        <Modal open={cancelTourModalOpen} 
        title={t('common.areYouSureDelete')}      
        onOk={handleCancelation}
        onCancel={handleCloseCancelTourModal}
        okText={t('common.yes')}
        cancelText={t('common.no')}>
              {t('schedule.areYouSureCancel')}
        </Modal> 
        <Modal open={bookedPersonsModalOpen} onCancel={handleClosePersonsBookedModal} footer={null} >
       
          <div> {t("schedule.totalEarnings")} : {tourEarningsTotal} </div>
          <div> {t("schedule.toPayOut")} : {tourEarnings} </div>
          <div>{t("schedule.fees")} : {tourFees} </div>
          <div> {t("schedule.paidOut")} : {tourPaidOut} </div>
          <div> {t("schedule.payOutDate")} : {tourPayOutDate} </div>
          {t("schedule.clients")}:
          <Table dataSource={clientsData} columns={columnsClients} size="small"
            pagination={{ defaultPageSize: 50 }}></Table>
        </Modal>
        <Modal open={addManyTourModalOpen} onCancel={handleCloseAddManyTourModal} footer={null} >
          <form className='center'>
            <div style={{ "marginTop": "20px" }} >

              <label className="form-label">{t("schedule.addManyTours")}:</label>
              <div>
                <DatePicker value={tourDayFrom} onChange={(value) => setTourDayFrom(value)} placeholder={t("common.dateFrom")} format="YYYY-MM-DD" className="mt-2 mb-1 lg:mt-4" />
              </div><div>

                <DatePicker value={tourDayTo} onChange={(value) => setTourDayTo(value)} placeholder={t("common.dateTo")} format="YYYY-MM-DD" className="mt-2 mb-1 lg:mt-4" />
              </div>
              <div>
                <Select
                  placeholder={t("schedule.selectDay")}
                  onChange={selectWeekDayHandle}
                  options={[
                    {
                      value: 1,
                      label: t("common.monday"),
                    },
                    {
                      value: 2,
                      label: t("common.tuesday"),
                    },
                    {
                      value: 3,
                      label: t("common.wednesday"),
                    },
                    {
                      value: 4,
                      label: t("common.thursday"),
                    },
                    {
                      value: 5,
                      label: t("common.friday"),
                    },
                    {
                      value: 6,
                      label: t("common.saturday"),
                    },
                    {
                      value: 0,
                      label: t("common.sunday"),
                    },
                  ]} />
              </div>
              <div>
                <div><TimePicker
                  onChange={handleTimeChangeManyTours}
                  value={selectedTimeMany}
                  placeholder="HH:MM"
                  format="HH:mm"
                  disableClock={true}
                /></div>

              </div>
              <div>
                <Checkbox checked={isActive} onChange={handleIsActiveChange}>{t('schedule.active')} </Checkbox>

              </div>


              <div>

                <Button onClick={handleAddManyTour}
                  type="button"
                  className="mt-1 mb-1 lg:mt-4"
                  style={{
                    borderRadius: '8px',
                    backgroundColor: 'rgb(255, 152, 0)',
                    color: 'rgb(17, 24, 39)',
                    border: '2px solid rgb(255, 152, 0)',

                  }}>{t("schedule.add")}</Button>
              </div>
            </div>
          </form>
        </Modal>


        <Modal open={addTourModalOpen} onCancel={handleCloseAddTourModal} footer={null} >
          <form className='center'>
            <div style={{ margin: "auto", width: "50%" }}>
              <label className="form-label">{t("schedule.newTour")}:</label>
              <DatePicker value={selectedDate} onChange={(value) => setSelectedDate(value)} placeholder={t("schedule.dateSelect")} format="YYYY-MM-DD" className="mt-2 mb-1 lg:mt-4" />
              <div><TimePicker
                onChange={handleTimeChange}
                value={selectedTime}
                format="HH:mm"
                disableClock={true}
                placeholder="HH:MM"
              /></div>

              <Button onClick={handleAddTour}
                type="button"
                className="mt-1 mb-1 lg:mt-4"
                style={{
                  borderRadius: '8px',
                  backgroundColor: 'rgb(255, 152, 0)',
                  color: 'rgb(17, 24, 39)',
                  border: '2px solid rgb(255, 152, 0)',
                }}>{t("schedule.add")}</Button>


            </div>
          </form>
        </Modal>
      </div>
    </Container>
  );
};

export default withGuideAccess(GuideTourScheduleForm);
