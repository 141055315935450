import React, { useState, useEffect } from 'react';
import { message, Button, Form, Rate,Input   } from 'antd';
import { Container ,TextArea} from 'react-bootstrap';
import axios from 'axios';
import API_URL from '../config';
import { useParams } from 'react-router-dom'; // Importuj useParams
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import "yet-another-react-lightbox/styles.css";

import withAuth from './withAuth';

const ReservationOpinion = () => {
  const { id } = useParams();
  const { tourDefinitionId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate(); 
  const [form] = Form.useForm();
  const [tourDefinition, setTourDefinition] = useState([]);


useEffect(() => {
  if (tourDefinitionId) {
    fetchData(tourDefinitionId);
  }
}, []);
const fetchData = async (tourDefinitionId) => {

  try {

    let url = API_URL + 'api/tours/' + tourDefinitionId;
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });

    setTourDefinition(response.data);

  } catch (error) {
    message.error(t('message.error'));
  }
}


  const onFinish = async (values) => {
    const data = {
      description: values.description,
      rate: values.rate,
    };
   
    await axios.patch(API_URL + 'api/reservations/' + id + '/opinions', data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    }).then(response => {
      
      message.success(t("message.saveOk"));
      navigate('/reservations');
    })
      .catch(error => {
        if(error.response.data === 'Tour : NullData')
        {
          message.error(t("reservations.errorTourNull"));
        }else{
          message.error(t("message.error"));

        }
      })
  }



  return (
    <div className="overflow-x-hidden">
      <Container style={{ backgroundColor: 'rgb(51, 51, 51)', height: "100%", padding: "30px" }}>
     

        <div style={{ background: 'rgb(252, 249, 249)', maxWidth: "1500px", padding: "30px" }}>
         <div style={{ textAlign: "center" }}><h3> {tourDefinition.title}</h3></div>
          <Form form={form} onFinish={onFinish} layout="vertical">

            <Form.Item name="description" label={t("reservations.description")} rules={[{ required: true }]}>
              <Input  />
            </Form.Item>
            <Form.Item name="rate" label={t("reservations.rate")} rules={[{ required: true }]}>
              <Rate />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {t("common.save")}
              </Button>
            </Form.Item>
          </Form>
        </div>

      </Container>
    </div>
  );
};

export default withAuth(ReservationOpinion);
