import React, {useState} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import LoginUpperSection from './LoginUpperSection';
import LoginLowerSection from './LoginLowerSection';
import LoginForm from './LoginForm';
import RegistrationForm from './RegistrationForm';

const LoginPage = () => {
    return (
        <Container>
                <Row style={{backgroundColor: 'rgb(51, 51, 51)'}}>
                <Col md={3} className="mx-auto">
                    <LoginForm/>
                </Col>
                <Col md={3} className="mx-auto">
                    <RegistrationForm/>
                </Col>
            </Row>
            <LoginUpperSection/>
            <LoginLowerSection/>
        </Container>
    );
};

export default LoginPage;
