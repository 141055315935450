import React, { useState, useEffect,useMemo } from 'react';
import { Table, Popconfirm, message, Button, Checkbox,Tooltip } from 'antd';
import withGuideAccess from './withGuideAccess';
import DisplayTextComponent from "./DisplayTextComponent.js";
import { Container, Row } from 'react-bootstrap';
import { EditOutlined, DeleteOutlined, CalendarOutlined ,MonitorOutlined,PlayCircleOutlined,PauseOutlined} from '@ant-design/icons';
import axios from 'axios';
import API_URL from '../config';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const GuideToursList = () => {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState([]);
  const [tourDefinitionId, setDefinitionTourId] = useState();
  const [scheduleTitle, setScheduleTitle] = useState("");
  const [arrow, setArrow] = useState('Show');

  const mergedArrow = useMemo(() => {
    if (arrow === 'Hide') {
      return false;
    }

    if (arrow === 'Show') {
      return true;
    }

    return {
      pointAtCenter: true,
    };
  }, [arrow]);
  const navigate = useNavigate();
  const handleOpenModalSchedule = (recordId, title) => {
    navigate('/guide/schedule/' + recordId);
    setDefinitionTourId(recordId);
    setScheduleTitle(title);
  };
  // Funkcja do obsługi zamykania modala

  const handleOpenModalEdit = async (recordId) => {
   
    navigate('/guide/edit-tour/' + recordId);


  };
const handlePreview = (record) => {
  window.open('/tours/'+record.id+"/"+record.region, '_blank');

}
  const handleOpenModal = () => {
    navigate('/guide/add-tour');

  };


  

  const columns = [

    {
      title: t("guideTourList.title"),
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => (<DisplayTextComponent data={text} />),  
    },
    {
      title: t("guideTourList.region"),
      dataIndex: 'region',
      key: 'region',
      responsive: ['lg'],
      width: 250
    },
    {
      title: t("guideTourList.status"),
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => {
        if (record.isActive === false) {
          return <span style={{ color: 'red' }}>{t("guideTourList.notActive")}</span>;
        } else if (!record.tours || record.tours.length === 0) {
          return <span style={{ color: 'red' }}>{t("guideTourList.noSchedule")}</span>;
        } else {
          return <span style={{ color: 'green' }}>{t("guideTourList.visible")}</span>;
        }
      },
      width: 50
    },
    {
      title: t("guideTourList.action"),
      key: 'action',
      render: (text, record) => (
        <span>
             <Tooltip placement="top" title={t('guideTourList.ttschedule')} arrow={mergedArrow} mouseEnterDelay={0.8}>
          <Button
            type="link"
            icon={<CalendarOutlined />}
            onClick={() => handleOpenModalSchedule(record.id, record.title)} />
            </Tooltip>
            <Tooltip placement="top" title={t('guideTourList.ttpreview')} arrow={mergedArrow} mouseEnterDelay={0.8}>
              <Button
            type="link"
            icon={<MonitorOutlined />}
            onClick={() => handlePreview(record)} />
              </Tooltip>
              <Tooltip placement="top" title={t('guideTourList.ttedit')} arrow={mergedArrow} mouseEnterDelay={0.8}>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleOpenModalEdit(record.id)} />
             </Tooltip>
           
           <Popconfirm
          title={t('schedule.areYouSureActivate') } 
          onConfirm={() => handleActivate(record.id)}
          okText={t('common.yes') }
          cancelText={t('common.no') }>
             <Tooltip placement="top" title={t('guideTourList.ttactivate')} arrow={mergedArrow} mouseEnterDelay={0.8}>
          <Button
        
            type="link"
            icon={<PlayCircleOutlined />}
            style={{ color: 'blue', background: '#fff' }}
            hidden={record.isActive}
          /></Tooltip>
          
        </Popconfirm>
        <Popconfirm
         title={t('schedule.areYouSureDeactivate') } 
          onConfirm={() => handleActivate(record.id)}
          okText={t('common.yes') }
          cancelText={t('common.no') }>
              <Tooltip placement="top" title={t('guideTourList.ttdeactivate')} arrow={mergedArrow} mouseEnterDelay={0.8}>
          <Button         
            type="link"
            icon={<PauseOutlined />}
            style={{ color: 'blue', background: '#fff' }}
            hidden={!record.isActive}
          />
          </Tooltip>
        </Popconfirm>
         
        </span>
      ),
      width: 200
    },
  ];
  useEffect(() => {
    fetchData();  
  }, []);


  const fetchData = async () => {

    try {
      const response = await axios.get(API_URL + 'api/tourdefinitions', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      const data = response.data;
      setDataSource(data);
    } catch (error) {
      console.error('Error creating tour definition:', error);
      message.error(t("message.error"));
    }

  };
  const handleActivate = async (id) => {
    try {
      await axios.patch(API_URL + 'api/tourdefinitions/' + id +'/activate',null, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
     

     
      await fetchData();
    } catch
    {
      message.error(t("message.error"));
    }

  };

  return (
    <Container >

      <Button
        variant="primary"
        className="mt-6 mb-6 lg:mt-8 md:w-max"
        style={{
          borderRadius: '8px',
          backgroundColor: 'rgb(255, 152, 0)',
          color: 'rgb(17, 24, 39)',
          border: '2px solid rgb(255, 152, 0)',
        }}
        onClick={handleOpenModal} // Dodajemy obsługę kliknięcia do otwarcia modala
      >
        {t("guideTourList.addTour")}
      </Button>
      <Table dataSource={dataSource} columns={columns} />

    </Container>
  );
};

export default withGuideAccess(GuideToursList);
