import React, { useState, useEffect } from 'react';
import { message,List, Avatar } from 'antd';
import { Container, Row } from 'react-bootstrap';
import axios from 'axios';
import API_URL from '../config';
import ToursListComponent from './ToursListComponent';
import ToursListMobileComponent from './ToursListMobileComponent';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
const GuidePreview = () => {
  const [dataSource, setDataSource] = useState([]);
  const [guideData, setGuideData] = useState();
  const [imageUrl, setImageUrl] = useState('../img/empty-avatar.webp');
  const [guideName, setGuideName] = useState();
  const [guideDescription, setGuideDescription] = useState();

  const [initLoading, setInitLoading] = useState(true);
  const { t } = useTranslation();
  const params= useParams()

  useEffect(() => {
  
    fetchData(params.name);
  }, []);


  const fetchData = async (name) => {

    try {

      let url = API_URL + 'api/Tours/guide/' + name;
      const response = await axios.get(url, {       
      });
      const data = response.data;
      setGuideData(response.data.guideData);
      if(response.data.guideData.imageUrl !== '')
      {
        let url =  `${API_URL}images/guide/${response.data.guideData.id}/thumb/thumb_${response.data.guideData.imageUrl}`;
        setImageUrl(url);
      }
      setDataSource(data.tours);
      let guideName =  response.data.guideData.isCompany === false ?  response.data.guideData.firstName + " " +response.data.guideData.lastName : response.data.guideData.companyName;
      setGuideName(guideName);
      setGuideDescription(response.data.guideData.aboutMe);
   
    } catch (error) {
      console.error('Error creating tour definition:', error);
      message.error(t("message.error"));
    }
    setInitLoading(false);
  };

 
  
  return (
    <div className="overflow-x-hidden">
      <Container style={{ height: "100vh" }}>
        <div style={{ maxWidth: "800px", margin: "auto" }}>  

        <div style={{fontSize:"18px",marginTop:"10px"}} className="max-w-5xl flex flex-col gap-4 justify-center text-center mx-auto items-center">
        
              <Avatar src={imageUrl} size={128}/>
              <div> {guideName}</div>
              <div> {guideDescription}</div>
              
             </div>       
             <div className='mobile-hidden'>
          <ToursListComponent dataSource={dataSource} initLoading={initLoading}/>
          </div>       
          <div className='desktop-hidden'>
          <ToursListMobileComponent dataSource={dataSource} initLoading={initLoading} className='desktop-hidden'/>
          </div>
        
        </div>
      </Container>
    </div>
  );
};

export default GuidePreview;
