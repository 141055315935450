import React, { useState, useEffect } from 'react';
import { Modal, Table, Popconfirm, message, Button, Radio, List, Avatar } from 'antd';
import { Container, Row } from 'react-bootstrap';
import axios from 'axios';
import API_URL from '../config';
import moment from 'moment';
import { Link } from 'react-router-dom';
import withAuth from './withAuth';
import { useTranslation } from 'react-i18next';
import DisplayTextComponent from "./DisplayTextComponent.js";
const Reservations = () => {
  const [dataSource, setDataSource] = useState([]);
  const [dateFrom, SetDateFrom] = useState('');
  const { t } = useTranslation();
  const [dateTo, SetDateTo] = useState('');
  const [selectedLocation, setSelectedLocation] = useState({
    label: '',
    location: {
      lat: 0,
      lng: 0,
    },
    place_id: 0
  });
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {

    try {

      let url = API_URL + 'api/Reservations';
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      const data = response.data;
      setDataSource(data);
    } catch (error) {

      message.error(t("message.error"));
    }
  };
  const handleCancel = async (tourClientId) => {
    try {

      let url = API_URL + 'api/Reservations/' + tourClientId;
      const response = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      await fetchData();
    } catch (error) {

      message.error(t("message.error"));
    }
  };
  const loadStripeClient = async (tourClientId) => {
    try {
      let url = API_URL + 'api/Reservations/' + tourClientId +'/payment';
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      const stripeUrl = await response.data;
      window.location.href = stripeUrl;
      // const stripe = await loadStripe('pk_test_51OYlU9Hdx4TuqxWv10fJTw33ELyZbiG7Pu0NoDvcujuSryM5w2npmkoCSQ13Afp51siJyb7USLky5h35H6AP7VwP00pnPJ6zzq');
      // const result = await stripe.redirectToCheckout({ sessionId: response.data });

      // if (result.error) {

      //   message.error(t("message.error"));
      // }
    } catch (error) {

      message.error(t("message.error"));
    }
  };
  const getStatus = (item) => {
    if (item.resignationAt !== null) {
      return t("reservations.statusResignation");
    }
    if (item.isCanceled) {
      return t("reservations.statusCancelled");
    }
    if (item.paidStatus) {
      return t("reservations.statusPaid");
    } else {
      return t("reservations.statusNotPaid");

    }

    // return item.resignationAt === null ? item.paidStatus ? t("reservations.statusPaid") : t("reservations.statusNotPaid") : t("reservations.statusResignation");
  };

  return (
    <div className="overflow-x-hidden">
      <Container style={{ color: "rgb(255, 255, 255)", height: "1000px" }}>
        <div style={{ maxWidth: "1200px", margin: "auto", paddingTop: "30px" }}>
          <List className='mobile-hidden' style={{ transition: 'background 0.3s' }}
            itemLayout="vertical"
            size="large"
            dataSource={dataSource}
            renderItem={(item, index) => (

              <List.Item
                key={item.tourId}
                extra={
                  
                  <div width={272}>
                    <div>{item.reservationNumber}</div>
                     {t("common.price")}: {item.price.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {item.currency}
                    <div>{t("reservations.paid")}: {item.paidAmount.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {item.currency}</div>
                    <div>
                      Status : {getStatus(item)}</div>
                    <div>{t("reservations.persons")} : {item.personsCount}</div>
                    <div style={{ marginTop: "10px" }} hidden={!item.canEdit}>
                      <Popconfirm
                        title={t('reservations.areYouSureResignation')}
                        onConfirm={() => handleCancel(item.tourClientId)}
                        okText={t('common.yes')}
                        cancelText={t('common.no')}>
                        <Button hidden={item.resignationAt !== null || !item.canEdit} className="button primary l w-full md:w-max" style={{ background: 'rgb(225, 62, 62)', height: '30px', borderRadius: '8px', color: 'rgb(244, 244, 244)', border: '2px solid rgb(225, 62, 62)' }}>
                          {t("reservations.cancel")}
                        </Button>
                      </Popconfirm>
                    </div>
                    <div style={{ marginTop: "10px" }} hidden={!item.canEdit}>
                      <Button onClick={() => { loadStripeClient(item.tourClientId) }} hidden={item.paidStatus || item.resignationAt !== null || !item.canEdit}
                        className="button primary l w-full md:w-max" style={{ background: 'rgb(0, 121, 107)', height: '30px', borderRadius: '8px', color: 'rgb(244, 244, 244)', border: '2px solid rgb(0, 121, 107)' }}>
                        {t("reservations.pay")}
                      </Button>

                    </div>

                  </div>
                }
              >
                <List.Item.Meta
                  avatar={<img hidden={item.mainImage == undefined}
                    width={100}
                    alt="logo"
                    src={item.mainImage == null ? '../img/empty.jpg' : API_URL + 'images/tours/' + item.tourDefinitionId + "/thumb/thumb_" + item.mainImage}
                  />}
                  title={<a href={"/tours/" + item.tourDefinitionId + "/"+item.region} > <DisplayTextComponent data={item.title}/></a>}
                  description={item.region}
                />
                <div> {t("reservations.date")} : {moment(item.date).format('YYYY-MM-DD HH:mm')} </div>
                <div> {t("common.guide")} : {item.guide} </div>
                <div> {t("common.phone")} : {item.phoneNumber} </div>
                {/* <div> {t("common.language")} : {item.language} </div> */}
                {/* {item.date} */}
                {/* </div> */}
              </List.Item>

            )}
          />
          <List className='desktop-hidden' style={{ transition: 'background 0.3s' }}
            itemLayout="vertical"
            size="large"
            dataSource={dataSource}
            renderItem={(item, index) => (

              <List.Item
                key={item.tourId}
              // extra={

              // }
              >
                <List.Item.Meta
                  avatar={<img
                    width={100}
                    alt="logo"
                    src={item.mainImage == null ? '../img/empty.jpg' : API_URL + 'images/tours/' + item.tourDefinitionId + "/thumb/thumb_" + item.mainImage}

                  />}
                  title={<a href={"/tours/" + item.tourDefinitionId+ "/"+item.region} ><DisplayTextComponent data={item.title} /></a>}
                  description={item.region}

                />
                <div>{item.reservationNumber}</div>

                <div> {t("reservations.date")} : {moment(item.date).format('YYYY-MM-DD HH:mm')} </div>
                <div> {t("common.guide")} : {item.guide} </div>
                <div> {t("common.phone")} : {item.phoneNumber} </div>
                {/* <div> {t("common.language")} : {item.language} </div> */}
                <div width={272}> {t("common.price")}: {item.price.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {item.currency}
                  <div>{t("reservations.paid")}: {item.paidAmount.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {item.currency}</div>
                  <div>Status : {item.resignationAt === null ? item.paidStatus ? t("reservations.statusPaid") : t("reservations.statusNotPaid") : t("reservations.statusResignation")}</div>
                  <div>{t("reservations.persons")} : {item.personsCount}</div>
                  <div style={{ marginTop: "10px", width: "150px" }} hidden={!item.canEdit}>
                    <Popconfirm

                      title={t('reservations.areYouSureResignation')}
                      onConfirm={() => handleCancel(item.tourClientId)}
                      okText={t('common.yes')}
                      cancelText={t('common.no')}>
                      <Button hidden={item.resignationAt !== null || !item.canEdit} className="button primary l w-full md:w-max"
                        style={{ background: 'rgb(225, 62, 62)', height: '30px', borderRadius: '8px', color: 'rgb(244, 244, 244)', border: '2px solid rgb(225, 62, 62)' }}>
                        {t("reservations.cancel")}
                      </Button>
                    </Popconfirm>
                  </div>
                  <div style={{ marginTop: "10px", width: "150px" }} hidden={!item.canEdit}>

                  <Button onClick={() => { loadStripeClient(item.tourClientId) }} hidden={item.paidStatus || item.resignationAt !== null || !item.canEdit}
                      className="button primary l w-full md:w-max"
                      style={{ background: 'rgb(0, 121, 107)', height: '30px', borderRadius: '8px', color: 'rgb(244, 244, 244)', border: '2px solid rgb(0, 121, 107)' }}>
                      {t("reservations.pay")}
                    </Button>

                  </div>
                </div>
                {/* {item.date} */}
                {/* </div> */}
              </List.Item>
            )}
          />
        </div>
      </Container >
    </div >
  );
};
export default withAuth(Reservations);
