import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const withAuth = (WrappedComponent) => {
    const ComponentWithAuth = () => {
        const navigate = useNavigate();
        const token = localStorage.getItem('accessToken') ? localStorage.getItem('accessToken') : '';
        const sExpirationDate = localStorage.getItem('expirationDate') ? localStorage.getItem('expirationDate') : '';
        const currentDate = new Date(); // Bieżąca data i czas
  
        useEffect(() => {
            if (!token || !sExpirationDate) {
                // Brak tokenu, przekieruj na stronę logowania lub wyświetl komunikat o braku dostępu
                window.location.href = '/login'; // Zmień na odpowiednią ścieżkę do strony logowania
            }
            var expirationDate = new Date(sExpirationDate);
            if(expirationDate < currentDate)
            {
                localStorage.removeItem('accessToken');
                localStorage.removeItem('accexpirationDateessToken');
                window.location.href = '/login';

            }
        }, [token, navigate]);

        return <WrappedComponent />;
    };

    return ComponentWithAuth;
};

export default withAuth;
