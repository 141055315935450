import React, {useState} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const LoginLowerSection = () => {
    const { t } = useTranslation();

    return (
        
        <Row>
        {/* <section className="flex-shrink-0" style={{backgroundColor: 'rgb(34, 34, 34)'}}>
            <div className="container mx-auto pt-12 lg:pt-14 xl:pt-20 testimonials-carousel pb-12 lg:pb-14 xl:pb-20">
                <div className="flex h-full my-auto">
                    <div className="max-w-5xl flex flex-col gap-4 justify-center text-center mx-auto items-center">
                        <h3 className="heading-medium" style={{color: 'rgb(255, 255, 255)', fontFamily: '"Rufina", serif', fontWeight: 700}}>
                        {t("login.loginPageSectionOpinion")}                             </h3>
                        <p className="body-small" style={{color: 'rgb(255, 255, 255)', fontFamily: '"PT Serif", serif', fontWeight: 400}}>- Sarah</p>
                    </div>
                </div>
            </div>
        </section> */}
    </Row>
    );
};

export default LoginLowerSection;
