import React, { useState, useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import '../../blog.css';
const Blog = () => {
    const { t } = useTranslation();


    return (

        <Container style={{ color: "rgb(0, 0, 0)", paddingTop: "10px", fontFamily: "PT Serif" }}>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="description" content={t("homepage.descriptionTag")} />
                <meta name="keywords" content={t("homepage.keyWords")} />
                <title>Najlepsze Atrakcje Turystyczne - Blog</title>
                <link rel="canonical" href="https://tourpilot.eu/blog" />
            </Helmet>
            <div className='blog'>
                <h2 style={{ textAlign: "center" }}>Najnowsze wpisy</h2>

                {/* <p>
                Polska to kraj bogaty w <strong>atrakcje turystyczne</strong>, które przyciągają turystów z całego świata. Niezależnie od tego, czy jesteś miłośnikiem historii, kultury, przyrody czy aktywnego wypoczynku, znajdziesz tu coś dla siebie. Oto lista najciekawszych miejsc, które warto odwiedzić.
            </p> */}
                <div className='blog-card-container '>
                    <div className="blog-card-locations"  >
                        <img src="img/gdansk.jpg" alt="Przewodnik Gdańsk" />
                        <a href="/blog/gdansk-atrakcje" ><h2>Gdańsk Atrakcje</h2></a>
                        <p>
                            Gdańsk, jedno z najstarszych miast Polski, oferuje wiele atrakcji w Gdańsku. Warto zwiedzić starówkę, zobaczyć Żuraw nad Motławą oraz Westerplatte.
                        </p>
                        <span>Data publikacji : 06.06.2024 </span>

                    </div>
                    {/* <h2><a href="/blog/gdansk-atrakcje"><strong>Gdańsk Atrakcje</strong></a></h2>
                    <p>
                        Gdańsk, jedno z najstarszych miast Polski, oferuje wiele atrakcji w Gdańsku. Warto zwiedzić starówkę, zobaczyć Żuraw nad Motławą oraz Westerplatte. Dla miłośników historii Muzeum II Wojny Światowej to miejsce obowiązkowe.
                    </p> */}
                    <div className="blog-card-locations"  >
                        <img src="img/warszawa.jpg" alt="Przewodnik Warszawa" />
                        <h2><a href="/blog/warszawa-atrakcje" >Warszawa Atrakcje</a></h2>
                        <p>
                            Stolica Polski to dynamiczne miasto, gdzie nowoczesne wieżowce sąsiadują z zabytkowymi budowlami. Koniecznie odwiedź Stare Miasto, Łazienki Królewskie oraz Muzeum Powstania Warszawskiego
                        </p>
                        <span>Data publikacji : 01.06.2024 </span>

                    </div>
                    {/* <h2><a href="/blog/warszawa-atrakcje"><strong>Warszawa Atrakcje</strong></a></h2>
                    <p>
                        Stolica Polski to dynamiczne miasto, gdzie nowoczesne wieżowce sąsiadują z zabytkowymi budowlami. Koniecznie odwiedź Stare Miasto, Łazienki Królewskie oraz Muzeum Powstania Warszawskiego. To tylko niektóre z licznych atrakcji w Warszawie.
                    </p>
                    <span>Data publikacji : 01.06.2024 </span> */}
                    <div className="blog-card-locations"  >
                        <img src="img/wroclaw.jpg" alt="Przewodnik Wrocław" />
                        <h2><a href="/blog/wroclaw-atrakcje" >Wrocław Atrakcje</a></h2>
                        <p>
                            Spacer po Ostrowie Tumskim, wizyta w Zoo oraz Hala Stulecia to tylko niektóre z atrakcji we Wrocławiu, które czekają na turystów.
                        </p>
                        <span>Data publikacji : 16.05.2024 </span>

                    </div>
                    {/* <h2><a href="/blog/wroclaw-atrakcje"><strong>Wrocław Atrakcje</strong></a></h2>
                    <p>
                        Wrocław zachwyca nie tylko architekturą, ale również atmosferą. Spacer po Ostrowie Tumskim, wizyta w Zoo oraz Hala Stulecia to tylko niektóre z atrakcji we Wrocławiu, które czekają na turystów.
                    </p>
                    <span>Data publikacji : 16.05.2024 </span> */}
                    <div className="blog-card-locations"  >
                        <img src="img/zakopane.jpg" alt="Przewodnik Zakopane" />
                        <h2><a href="/blog/zakopane-atrakcje" >Zakopane Atrakcje</a></h2>
                        <p>
                            Krupówki, Gubałówka i Morskie Oko to najważniejsze atrakcje w Zakopanem, które warto zobaczyć.
                        </p>
                        <span>Data publikacji : 10.05.2024 </span>

                    </div>
                    {/* <h2><a href="/blog/zakopane-atrakcje"><strong>Zakopane Atrakcje</strong></a></h2>
                    <p>
                        Zakopane to idealne miejsce dla miłośników górskich wędrówek i sportów zimowych. Krupówki, Gubałówka i Morskie Oko to najważniejsze atrakcje w Zakopanem, które warto zobaczyć.
                    </p>
                    <span>Data publikacji : 10.05.2024 </span> */}
                    <div className="blog-card-locations"  >
                        <img src="img/mazury.jpg" alt="Przewodnik Mazury" />
                        <h2><a href="/blog/mazury-atrakcje" >Mazury Atrakcje</a></h2>
                        <p>
                            Żeglarstwo, kajakarstwo oraz relaks nad jeziorami to tylko niektóre z atrakcji na Mazurach.
                        </p>
                        <span>Data publikacji : 04.05.2024 </span>

                    </div>
                    {/* <h2><a href="/blog/mazury-atrakcje"><strong>Mazury Atrakcje</strong></a></h2>
                    <p>
                        Mazury to raj dla miłośników wodnych sportów i przyrody. Żeglarstwo, kajakarstwo oraz relaks nad jeziorami to tylko niektóre z atrakcji na Mazurach.
                    </p>
                    <span>Data publikacji : 04.05.2024 </span> */}
                    <div className="blog-card-locations"  >
                        <img src="img/karkow.jpg" alt="Przewodnik Kraków" />
                        <h2><a href="/blog/krakow-atrakcje" >Kraków Atrakcje</a></h2>
                        <p>
                        Wawel, Rynek Główny oraz Kazimierz to tylko niektóre z atrakcji w Krakowie, które przyciągają turystów .
                        </p>
                        <span>Data publikacji : 21.05.2024 </span>

                    </div>
                    {/* <h2><a href="/blog/krakow-atrakcje"><strong>Kraków Atrakcje</strong></a></h2>
                    <p>
                        Kraków, dawna stolica Polski, zachwyca swoim urokiem i historią. Wawel, Rynek Główny oraz Kazimierz to tylko niektóre z atrakcji w Krakowie, które przyciągają turystów .
                    </p>
                    <span>Data publikacji : 21.05.2024 </span> */}


                    <br />
                </div>
            </div>
        </Container >

    );
};
export default Blog;
