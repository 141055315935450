import React, { useState, useEffect } from 'react';
import {  message, Button} from 'antd';
import { Container, Row } from 'react-bootstrap';
import axios from 'axios';
import API_URL from '../config';
import SearchForm from './SearchForm';
import ToursListComponent from './ToursListComponent';
import ToursListMobileComponent from './ToursListMobileComponent';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { useParams } from 'react-router-dom';

const ToursList = () => {
  const [dataSource, setDataSource] = useState([]);
  const [dateFrom, SetDateFrom] = useState((new Date()).toISOString().slice(0, 19).replace('T', ' '));
  const [dateTo, SetDateTo] = useState((new Date(new Date().getTime() + (7 * 24 * 60 * 60 * 1000))).toISOString().slice(0, 19).replace('T', ' '));

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [selectedLocation, setSelectedLocation] = useState({
    label: '',
    location: {
      lat: 0,
      lng: 0,
    },
    place_id: 0
  });
  const navigate = useNavigate();
  const { region } = useParams();

  const decodedRegion = decodeURIComponent(region);
  useEffect(() => {

    const urlParams = new URLSearchParams(window.location.search);

    selectedLocation.label = decodedRegion;


    let dateFromParam = dateFrom;
    let dateToParam = dateTo;
    if (urlParams.get('from') && urlParams.get('to')) {
      dateFromParam = urlParams.get('from');
      dateToParam = urlParams.get('to');

    }

    SetDateFrom(dateFromParam);
    SetDateTo(dateToParam);
    fetchData(dateFromParam, dateToParam, pageSize);
  

  }, []);

  const fetchCoordinates = async (region) => {
    try {
      const response = await axios.get(
        'https://maps.googleapis.com/maps/api/geocode/json',
        {
          params: {
            address: region,
            key: process.env.REACT_APP_GOOGLE_API_KEY, // Wstaw swój klucz API Google Maps
          },
        }
      );

      if (response.data.status === 'OK') {
        const location = response.data.results[0].geometry.location;
        selectedLocation.location.lng = location.lng;
          selectedLocation.location.lat = location.lat;     
          selectedLocation.place_id = response.data.results[0].place_id;
        return { lat: location.lat, lng: location.lng };

        // setCoordinates({ lat: location.lat, lng: location.lng });
      } else {
        message.error(t('Nie udało się znaleźć lokalizacji'));
      }
    } catch (error) {
      message.error(t('Wystąpił błąd podczas wyszukiwania lokalizacji'));

    } finally {
      setLoading(false);
    }
  };
  const handleSearchSubmit = (selectedLocation, dateTimeFrom, dateTimeTo) => {

    let url = '/atrakcje/' + selectedLocation.label + "?from=" + dateTimeFrom + "&to=" + dateTimeTo;

    window.location.href = url;

  };
  const fetchData = async (from, to, pageSizeParam) => {

    try {
      setLoading(true);

      const coordinates = await fetchCoordinates(region);
      if(coordinates === undefined)
        return;
      let url = API_URL + 'api/tours?lat=' + coordinates.lat + '&lng=' + coordinates.lng + '&skip=0&take=' + pageSizeParam; //from=' + from + '&to=' + to + '&lng=' + lng + '&
      if (from !== null) {
        url += '&from=' + from;
      }
      if (to !== null) {
        url += '&to=' + to;
      }
      const response = await axios.get(url);
      const data = response.data;
      setDataSource(data);
      setLoading(false);

    } catch (error) {
      console.error('Error creating tour definition:', error);
      message.error(t("message.error"));
    } finally{

    }
  };
  const onLoadMore = () => {
    setLoading(true);
    setPageSize(pageSize + 1);
    fetchData(selectedLocation.location.lat, selectedLocation.location.lng, dateFrom, dateTo, pageSize + 1);
  }
  const loadMore =
    !loading ? (
      <div
        style={{
          textAlign: 'center',
          marginTop: 12,
          height: 32,
          lineHeight: '32px',
        }}
      >
        <Button onClick={onLoadMore}>{t("common.more")}</Button>
      </div>
    ) : null;
  return (
    <div className="overflow-x-hidden">
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={t("homepage.descriptionTag")} />
        <meta name="keywords" content={t("homepage.keyWords")} />
        <title>Atrakcje Turystyczne {selectedLocation.label}</title>
        <link rel="canonical" href={"https://tourpilot.eu/atrakcje/"+selectedLocation.label} />
      </Helmet>
      <Container >
        <div style={{ maxWidth: "800px", margin: "auto" }}>
          <h1 className="heading-medium mb-6 break-word text-center">Atrakcje {selectedLocation.label}</h1>
          <SearchForm location={selectedLocation} dateFromParam={dateFrom} dateToParam={dateTo} handleSearchSubmit={handleSearchSubmit} />
          <div className='mobile-hidden'>
            <ToursListComponent dataSource={dataSource} initLoading={loading} />
          </div>
          <div className='desktop-hidden'>
            <ToursListMobileComponent dataSource={dataSource} initLoading={loading} className='desktop-hidden' />
          </div>

        </div>
      </Container>
    </div>
  );
};

export default ToursList;
