import React, { useState, useEffect } from 'react';
import { Select, message, Button, Form, InputNumber, Modal, DatePicker, Rate, Checkbox } from 'antd';
import { ClockCircleOutlined, AimOutlined } from '@ant-design/icons';
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import API_URL from '../config';
import { useParams } from 'react-router-dom'; // Importuj useParams
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import moment from 'moment';
import LoginForm from './LoginForm';
import { useTranslation } from 'react-i18next';
import PerPersonComponent from "./PerPersonComponent.js";
import DisplayTextComponent from "./DisplayTextComponent.js";
import SelectedLanguagesDisplay from "./SelectedLanguagesDisplay.js";
import { Helmet } from "react-helmet";
const TourPreview = () => {
  const [tourDefinition, setTourDefinition] = useState([]);
  const { id } = useParams();
  const [images, setImages] = useState([]);
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [selectedTourId, setSelectedTourId] = useState(null);
  const [maxCapacity, setMaxCapacity] = useState(0);
  const [hideMaxCapacity, setHideMaxCapacity] = useState(false);
  const [toursHours, setToursHours] = useState([]);
  const [form] = Form.useForm();
  const [totalPrice, setTotalPrice] = useState(0);
  const [personsNumber, setPersonsNumber] = useState(0);
  const [isModalOpenLogin, setModalOpenLogin] = useState(false);
  const [paymentType, setPaymentType] = useState();
  const [verification, setVerification] = useState();
  const [amountLeft, setAmountToBePaid] = useState(0);
  const [amountPaid, setAmountPaid] = useState(0);
  const [payLaterHidden, setPayLaterHidden] = useState();
  const [datePickerMap, setDatePickerMap] = useState([]);
  const [perPerson, setPerPerson] = useState(false);
  const [perPersonPriceJson, setPerPersonPriceJson] = useState([]);
  const [selectedPersonsDetails, setSelectedPersonsDetails] = useState([]);
  const [tourPrice, setTourPrice] = useState();
  const [selectedDate, SetSelectedDate] = useState();
  const [acceptRegulations, setAcceptRegulations] = useState(false);
  const [policyConsent, setPolicyConsent] = useState(false);

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, []);

  

  const handleStripeCheckout = async () => {
    const dto = {
      paymentType: paymentType,
      tourId: parseInt(selectedTourId),
      PersonsCount: personsNumber,
      ClientUrl: window.location.origin,
      SelectedPersonsDetails: JSON.stringify(selectedPersonsDetails),
      TourDate: selectedDate
    };
    let url = API_URL + 'api/Checkout';
    try {
      const response = await axios.post(url, dto, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      const stripeUrl = await response.data;
      window.location.href = stripeUrl;
    } catch (error) {

      // Access the response data
      const responseData = error.response.data;

      // Convert response data to string if it's not already
      const responseString = typeof responseData === 'string' ? responseData : JSON.stringify(responseData);

      // Check if the response contains the specific string
      if (responseString.includes('To much person')) {
        message.error(t('previewtour.tooMuchPersons'));

      } else if (responseString.includes('PersonsCount error')) {
        message.error(t('previewtour.personsCountError'));

      } else {
        message.error(t('message.error'));

      }


    }

  };

  const onOrder = async (values) => {

    if (acceptRegulations !== true) {
      message.error(t('previewtour.acceptRegulationsError'));
      return;
    }
    if (policyConsent !== true) {
      message.error(t('login.termsPrivacyRequired'));
      return;
    }
    var totalCount = selectedPersonsDetails.reduce((sum, item) => sum + item.count, 0);
    if (totalCount > maxCapacity) {
      message.error(t('previewtour.tooMuchPersons'));
      return;
    }
    if (localStorage.getItem('accessToken') === null) {
      handleOpenModalLogin();
    } else {
      handleStripeCheckout();

    }
  }
  const handleOpenModalLogin = () => {
    setModalOpenLogin(true);
  };

  const handleCloseModalLogin = () => {
    setModalOpenLogin(false);
  };


  const fetchData = async (tourDefinitionId) => {

    try {

      let url = API_URL + 'api/tours/' + tourDefinitionId;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      let toursDatesArray = [];
      response.data.tours.forEach((item) => {
        const dateTime = `${moment(item.date).format('YYYY-MM-DD')}`;
        toursDatesArray.push(dateTime);

      });
      setDatePickerMap(toursDatesArray);

      response.data.imagesUrl.forEach((imageName) => {
        const newImage = {
          src: `${API_URL}images/tours/${id}/${imageName}`,
        };
        images.push(newImage);
      });
      setPaymentType(response.data.owner.paymentType);
      setVerification(response.data.owner.verification);
      setPayLaterHidden(response.data.owner.paymentType === 1);
      setTourDefinition(response.data);
      setPerPerson(response.data.perPerson);
      setTourPrice(parseFloat(response.data.price));
      setPerPersonPriceJson(response.data.perPersonPriceJson);

    } catch (error) {
      message.error(t('message.error'));
    }
  };

  const handleTourDateChange = (selectedItem) => {
    var date = toursHours.find(x => x.value === selectedItem);
    if (date !== undefined) {
      setSelectedTourId(date.value);
      setMaxCapacity(date.capacity);
      setHideMaxCapacity(false);
      let fullTourDateUI = selectedDate + " " + date.label;
      SetSelectedDate(fullTourDateUI);
    }

  };

  const handleDateChange = async (date) => {
    let toursDataSet = [];
    form.setFieldValue('TimeOfTour', undefined);

    if (date === null) {
      setToursHours(toursDataSet);
      return;
    }

    try {
      var dateTime = date.format('YYYY-MM-DD');
      SetSelectedDate(dateTime);
      let url = API_URL + 'api/tours/' + id + "/" + dateTime;;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });



      response.data.forEach((item) => {
        let dateTime = `${moment(item.hour).format('HH:mm')}`;
        toursDataSet.push({ 'label': dateTime, 'value': item.tourId, 'capacity': item.capacity });


      });
      setToursHours(toursDataSet);
    } catch (error) {
      message.error(t('message.error'));
    }

  };
  const handleOnPersonsChange = (value) => {

    setSelectedPersonsDetails(value);

  };

  const handlePriceChange = (value) => {
    setTotalPrice(value);

    if (paymentType === 1) {
      setAmountToBePaid(0);

    } else if (paymentType === 2) {
      let onlyFee = value * 15 / 100;
      setAmountPaid(onlyFee);
      setAmountToBePaid(value - onlyFee);

    }



  };
  const disabledDate = (current) => {
    const dateKey = current.format('YYYY-MM-DD');
    return !datePickerMap.includes(dateKey);
  };
  const cellRender = React.useCallback((current, info) => {
    if (info.type !== 'date') {
      return info.originNode;
    }

    return (
      <div className={`ant-picker-cell-inner ${disabledDate(current) ? 'disabled' : ''}`} >
        {current.date()}
      </div>
    );
  }, []);
  const handleAcceptRegulations = (e) => {
    setAcceptRegulations(e.target.checked);
  }
  const handleCheckboxPolicy = (e) => {
    setPolicyConsent(e.target.checked);
  }
  const validateCheckbox = (_, value) => {
    if (value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(t('previewtour.acceptRegulationsError')));
  };
  return (

    <div >
       <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={<DisplayTextComponent data={tourDefinition.description} />} />
        <meta name="keywords" content={t("homepage.keyWords")} />
        <meta name="title" content={<DisplayTextComponent data={tourDefinition.title} />} />       
        <link rel="canonical" href={"https://tourpilot.eu/tours/"+id+"/"+tourDefinition.region} />
      </Helmet>
      <div style={{ maxWidth: "1500px", paddingTop: "15px", borderRadius: "7px", padding: "10px" }}>
        <Row gutter={[16, 0]}>
          <Col md={8}>
            <Row>
              <Col md={5} hidden={tourDefinition.mainImage == undefined}>
                <img id="mainImagePreview"
                  src={tourDefinition.mainImage == undefined ? '../img/empty.jpg' : `${API_URL}images/tours/${id}/thumb/thumb_${tourDefinition.mainImage}`}
                  style={{ width: '400px', height: '300px', cursor: 'pointer' }}
                  onClick={() => setOpen(true)}
                />
                <Lightbox
                  open={open}
                  close={() => setOpen(false)}
                  slides={images}
                />
              </Col>
              <Col md={4}>

                <h1 style={{ paddingTop: "10px", fontSize: "30px", fontWeight: "bold" }}><DisplayTextComponent data={tourDefinition.title} /></h1>
                <div style={{ paddingTop: "20px", fontSize: "20px", fontWeight: "bold" }}><h4>{tourDefinition.region}</h4></div>
                <div style={{ paddingTop: "20px", fontSize: "18px" }}><DisplayTextComponent data={tourDefinition.descriptionShort} /></div>

                <div style={{ paddingTop: "20px", fontSize: "18px" }}><Rate disabled value={tourDefinition.rate} /></div>
                <div style={{ fontSize: "20px", paddingTop: "20px" }}>

                  {tourDefinition.owner == undefined ? '' :
                    <a href={"/guide/" + tourDefinition.owner.urlPreviewParam}  > {tourDefinition.owner.firstName + " " + tourDefinition.owner.lastName}</a>
                  }</div>
                <div style={{ paddingTop: "20px", fontSize: "18px", display: 'flex', }}> <span>{t("common.language")} : </span> <SelectedLanguagesDisplay selectedLanguages={tourDefinition.languages} /></div>

              </Col>

            </Row>
            <Row gutter={[16, 0]}>
              <Col md={12}>
                <div style={{ paddingTop: "20px", margin: "auto", width: "100%", fontSize: "20px" }}>
                  <DisplayTextComponent data={tourDefinition.description} />
                </div>

                <div style={{ paddingTop: "20px", margin: "auto", width: "100%", fontSize: "20px" }}>
                  <ClockCircleOutlined style={{ fontSize: "25px" }} />  {t("previewtour.tourLenght")} : {tourDefinition.tourLenght} h
                </div>
                <div style={{ paddingTop: "20px", margin: "auto", width: "100%", fontSize: "20px" }}>
                  <AimOutlined style={{ fontSize: "25px" }} />  {t("previewtour.meetingPoint")} :<DisplayTextComponent data={tourDefinition.meetingPoint} />
                </div>

                <br />

              </Col>

            </Row>
            <Row>
              <div>{t("previewtour.regulations")}</div>
              <div>{t("previewtour.regulations1")}</div>
              <div hidden={payLaterHidden}>{t("previewtour.regulations2")}</div>
              <div>{t("previewtour.regulations4")}</div>
              <div>{t("previewtour.regulations5")}</div>
            </Row>
          </Col>
          <Col md={3}>
            <Row gutter={[16, 0]}>


            </Row>
            <Row md={4} className='bookingBox' hidden={verification < 1}>
              <div style={{ padding: "20px", margin: "auto", width: "100%" }} hidden={perPerson}>
                <b> {t('common.price')} {tourDefinition.price} {tourDefinition.currency} </b>
              </div>
              <div style={{ padding: "20px", margin: "auto", width: "100%" }}>
                <Form form={form} onFinish={onOrder} layout="vertical">
                  <Form.Item name="DateOfTour" label={t("previewtour.selectdate")} rules={[{ required: true }]}>
                    <DatePicker cellRender={cellRender} disabledDate={disabledDate} onChange={handleDateChange} placeholder={t("previewtour.selectdatePicker")} />
                  </Form.Item>
                  <Form.Item name="TimeOfTour" label={t("previewtour.selecttime")} rules={[{ required: true }]}>
                    <Select
                      style={{ width: "auto", minWidth: "150px" }}
                      onChange={handleTourDateChange}
                      options={toursHours}
                    />
                  </Form.Item>
                  <Form.Item>
                    <div hidden={hideMaxCapacity}>{t('previewtour.capacity')} {maxCapacity}</div>

                  </Form.Item>
                  <Form.Item>
                    <PerPersonComponent currency={tourDefinition.currency} data={perPersonPriceJson} onPriceChange={handlePriceChange} onPersonsChange={handleOnPersonsChange} perPerson={perPerson} priceForAll={tourDefinition.price} maxCapacity={maxCapacity} />

                  </Form.Item>

                  <Form.Item name="Summary" label={t('previewtour.summary')} hidden={!payLaterHidden}>
                    {totalPrice.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {tourDefinition.currency}
                  </Form.Item>
                  <Form.Item name="AmountPaid" label={t('previewtour.paynowreservationfee')} hidden={payLaterHidden}>
                    {amountPaid.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {tourDefinition.currency}
                  </Form.Item>
                  <Form.Item name="AmountLeft" label={t('previewtour.paylater')} hidden={payLaterHidden}>
                    {amountLeft.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {tourDefinition.currency}
                  </Form.Item>
                  <Form.Item name="AcceptRegultions" valuePropName="checked"
                    rules={[{ required: true, message: t('previewtour.acceptRegulationsError') }]}
                  >
                    <Checkbox required onChange={handleAcceptRegulations}> {t("previewtour.acceptRegulations")} <a href='01. Regulamin Seriwsu internetowego.pdf' target="_blank">{t("regulations.regulations")}*</a></Checkbox>
                  </Form.Item>
                  <Form.Item name="AcceptPrivacyPolicy" valuePropName="checked"
                    rules={[{ required: true, message: t('previewtour.acceptRegulationsError') }]}
                  ><Checkbox
                    onChange={handleCheckboxPolicy}
                    required
                  >{t("login.policyConsent")} <a href='privacy policy.pdf' target="_blank">{t("login.policy")}</a>*</Checkbox>
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" htmlType="submit" className="button primary xl w-full md:w-max" style={{ background: 'rgb(255, 152, 0)', height: '50px', borderRadius: '8px', color: 'rgb(17, 24, 39)', border: '2px solid rgb(255, 152, 0)' }}>
                      {t('common.booknow')}
                    </Button>
                  </Form.Item>
                </Form>

              </div>

            </Row>

          </Col>
        </Row>

      </div>
      <Modal open={isModalOpenLogin} onCancel={handleCloseModalLogin} footer={null} >
        <Row >
          <Col className="mx-auto">
            <LoginForm className="modalLoginForm" />
          </Col>
        </Row>
      </Modal>

    </div>

  );
};

export default TourPreview;
