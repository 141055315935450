import React, { useState, useEffect } from 'react';
import LocationAutosuggest from "./LocationAutosuggest.js";
import { DatePicker, message } from 'antd';
import { useNavigate } from "react-router-dom"
import moment from 'moment';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const SearchForm = ({ location, dateFromParam, dateToParam, handleSearchSubmit }) => {
    const { t } = useTranslation();
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState({
        label: '',
        location: {
            lat: 0,
            lng: 0,
        },
        place_id: 0
    });

    useEffect(() => {
        if (location) {
            setSelectedLocation(location);
        }
        if (dateFromParam) {
            setDateFrom(dayjs(dateFromParam, 'YYYY-MM-DDTHH:mm:ss'));

        }
        if (dateToParam) {
            setDateTo(dayjs(dateToParam, 'YYYY-MM-DDTHH:mm:ss'));


        }
    }, [location, dateFromParam, dateToParam]);


    const handleSearch = (e) => {
        e.preventDefault();
        if (selectedLocation.place_id === 0) {
            message.info(t("message.Pleaseselectlocation"));
            return;
        }
        if (dateFrom === null || dateTo === null) {
            message.info(t("message.Pleaseselectdaterange"));
            return;
        }

        const dateTimeFrom = moment(`${moment(new Date(dateFrom)).format('YYYY-MM-DD')} 00:00:00`, 'YYYY-MM-DD HH:mm').format(
            'YYYY-MM-DDTHH:mm:ss');

        const dateTimeTo = moment(`${moment(new Date(dateTo)).format('YYYY-MM-DD')} 23:59:59`, 'YYYY-MM-DD HH:mm').format(
            'YYYY-MM-DDTHH:mm:ss');
        //  navigate('/tours/list?from='+dateTimeFrom+'&to='+dateTimeTo+'&lng='+selectedLocation.location.lng+'&lat='+selectedLocation.location.lat+"&location="+selectedLocation.label);
        // Tutaj możesz dodać logikę wyszukiwania

        handleSearchSubmit(selectedLocation, dateTimeFrom, dateTimeTo);
    };


    // Funkcja obsługująca zmiany lokalizacji
    const handleLocationChange = (location) => {
        setSelectedLocation(location);
    };
    return (
        <form className="search-form" onSubmit={handleSearch}>
            <div className="input-search">
                <LocationAutosuggest onLocationChange={handleLocationChange} location={selectedLocation} />
            </div>


            <div className="date-picker">
                <DatePicker
                    id="date-from"
                    value={dateFrom}
                    onChange={(date) => setDateFrom(date)}
                    dateFormat="yyyy-MM-dd"
                    placeholder={t("common.dateFrom")}

                />
            </div>
            <div className="date-picker">
                <DatePicker
                    id="date-to"
                    value={dateTo}
                    onChange={(date) => setDateTo(date)}
                    dateFormat="yyyy-MM-dd"
                    placeholder={t("common.dateTo")}
                />
            </div>

            <button className="button primary xl w-full md:w-max" style={{ background: 'rgb(255, 152, 0)', height: '40px', borderRadius: '8px', color: 'rgb(17, 24, 39)', border: '2px solid rgb(255, 152, 0)' }} type="submit">{t('common.searchText')}</button>
        </form>
    );
};

export default SearchForm;
