import React, { useState } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import jwt_decode from 'jwt-decode';
import { useTranslation } from 'react-i18next';

const NavMenu = () => {
  const [collapsed, setCollapsed] = useState(true);
  const { t } = useTranslation();

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  const handleLogout = () => {
    // Logout - remove the token from local storage
    localStorage.removeItem('accessToken');
    window.location.href = '/login';
  };

  const token = localStorage.getItem('accessToken');
  const sExpirationDate = localStorage.getItem('expirationDate');
  var expirationDate = new Date(sExpirationDate);
  const currentDate = new Date();
  let isGuide = false;
  let isFullyVerified = localStorage.getItem('fullyVerified'); 

  if (token) {
    const decodedToken = jwt_decode(token);
    isGuide = decodedToken.type.toLowerCase() === 'true';
  }

  return (
    <header style={{ backgroundColor: 'rgb(0, 121, 107)' }}>
      <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white " container light>
        <NavbarBrand tag={Link} to="/">
          <img src="img/logo_white.png" width={80} height={45} alt="TourPilot lokalne atrakcje"/>
        </NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} className="mr-2" />
        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
          <ul className="navbar-nav flex-grow">
            {(!token || currentDate > expirationDate) && (
              <>
              <NavItem>
                  <NavLink tag={Link} className="nav-menu-text" to="/blog">
                  Blog
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} rel="nofollow" className="nav-menu-text" to="/login">
                  {t('login.login')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} rel="nofollow" className="nav-menu-text" to="/register/guide">
                    {t('login.registerGuide')} {/* Assuming 'menu.registerGuide' is a valid translation key */}
                  </NavLink>
                </NavItem>
              </>
            )}
            {token && isGuide && currentDate < expirationDate && (
              <>
                                 
                <NavItem>
                  <NavLink tag={Link} className="nav-menu-text" to="/guide/list">
                  {t('login.tours')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="nav-menu-text" to="/guide">
                  {t('login.settings')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="nav-menu-text" to="/" onClick={handleLogout}>
                  {t('login.logout')}
                  </NavLink>
                </NavItem>
              </>
            )}
            {token && !isGuide && currentDate < expirationDate && (
              <>
               
                <NavItem>
                  <NavLink tag={Link} className="nav-menu-text" to="/reservations">
                  {t('login.reservations')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="nav-menu-text" to="/" onClick={handleLogout}>
                  {t('login.logout')}
                  </NavLink>
                </NavItem>
              </>
            )}
          </ul>
        </Collapse>
      </Navbar>
    </header>
  );
};

export default NavMenu;
