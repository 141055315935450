// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import backend from "i18next-http-backend";
import translationEn from "../src/lang/translation.en.json";
import translationPl from "../src/lang/translation.pl.json";
const options = {
  order: ['navigator'],
  lookupQuerystring: 'lng'
}


const languages = ["pl"]; // (*)

const resource = {
  // en: {
  //   translation: translationEn,
  // },
  pl: {
    translation: translationPl,
  },
};


i18n
.use(initReactI18next)
.use(LanguageDetector) 
.use(backend) 
  .init({
    resources: resource,
    // resources: {
    //   en: {
    //     translation: {
    //       // English translations
    //       greeting: 'Hello!',
    //     },
    //   },
    //   pl: {
    //     translation: {
    //       // Polish translations
    //       greeting: 'Cześć!',
    //     },
    //   },
    // },
    fallbackLng: 'pl',
    debug: false,
    detection: options,
  });

export default i18n;
