import React, { Component } from 'react';
import { Container } from 'reactstrap';
import  NavMenu  from './NavMenu';
import Footer from "./Footer";
import CookieConsentComponent from "./CookieConsent.js";

export class Layout extends Component {
  static displayName = Layout.name;
    constructor(props) {
        super(props);
        this.state = {
            token: ''
        };
    }

    componentDidMount() {
        const storedToken = localStorage.getItem('accessToken');
        const tokenExpiration = localStorage.getItem('expirationDate') ? localStorage.getItem('expirationDate') : '';  
        const currentDate = new Date(); // Bieżąca data i czas
        
        if (storedToken && tokenExpiration) {
          var expirationDate = new Date(tokenExpiration);
          if(expirationDate < currentDate)
          {
              localStorage.removeItem('accessToken');
              localStorage.removeItem('accexpirationDateessToken');  

          }else{
            const oneHourLater = new Date(currentDate.getTime() + (1 * 60 * 60 * 1000)); // Dodanie jednej godziny (1 * 60 * 60 * 1000 milisekund)

            localStorage.setItem('expirationDate', oneHourLater); 
            this.setState({ token: storedToken });
          }
        }
    }
  render() {
      const { token } = this.state;
      
      return (
        <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
    <NavMenu token={token} style={{ flex: "0 0 auto" }} />
    <Container tag="main" style={{ fontFamily: "'PT Serif', serif", flex: 1, minHeight: 0 }}>
    <CookieConsentComponent />
      {this.props.children}
    </Container>
    <Footer style={{ flex: "0 0 auto" }} />
  </div>
      );
      
  }
}
