// App.js
import React, { Component } from 'react';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'; // Import the i18n configuration
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import './custom.css';
import './site.css';
import './control.css';

export default class App extends Component {
  static displayName = App.name;

  componentDidMount() {
    // Definiujemy fbq jako zmienną globalną
    window.fbq = window.fbq || function() {
      window.fbq.callMethod
        ? window.fbq.callMethod.apply(window.fbq, arguments)
        : window.fbq.queue.push(arguments);
    };
    if (!window._fbq) window._fbq = window.fbq;
    window.fbq.push = window.fbq;
    window.fbq.loaded = true;
    window.fbq.version = '2.0';
    window.fbq.queue = [];

    // Załadowanie skryptu Facebook Pixel
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://connect.facebook.net/en_US/fbevents.js';
    document.head.appendChild(script);

    // Inicjalizacja Pixel i śledzenie PageView
    window.fbq('init', '9058961704130158');
    window.fbq('track', 'PageView');
  }

  render() {
    return (
      <I18nextProvider i18n={i18n}>
        <Layout>
          <Routes>
            {AppRoutes.map((route, index) => {
              const { element, ...rest } = route;
              return <Route key={index} {...rest} element={element} />;
            })}
          </Routes>
        </Layout>
      </I18nextProvider>
    );
  }
}