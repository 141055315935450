import React, { useState,useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import API_URL from '../config';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
const Confirmation = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [confimationMessage, setConfimationMessage] = useState('');

    useEffect(() => {
        const token = new URLSearchParams(location.search).get('token');
        if (token) {
            const encodedToken = encodeURIComponent(token);
            confirmToken(token);
        } else {
            // Brak tokenu w querystring, obsłuż odpowiednio
        }
    }, []); // Pusta tablica zależności

    const confirmToken = async (token) => {
        try {
            const response = await fetch(API_URL+'api/auth/confirm?token=' + token, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                }});
            if (response.ok) {
                // Token został pomyślnie potwierdzony, przekieruj na wskazaną stronę
                window.location.href = '/login';
            } else {
                // Wystąpił błąd podczas potwierdzania tokenu, obsłuż odpowiednio
                setConfimationMessage(t("message.error"))

            }
        } catch (error) {
            var e = error;
            // Wystąpił błąd połączenia z API, obsłuż odpowiednio
        }
    };

    return (
        <Container style={{backgroundColor: 'rgb(51, 51, 51)',color:"rgb(255, 255, 255)",height:"1000px" }}>
        <div style={{margin:'auto',padding:"300px"}}>
           <h1>{confimationMessage}</h1>           
        </div>
        </Container>
    );
};

export default Confirmation;
