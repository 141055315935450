import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import ApiClient from './ApiClient';
import API_URL from '../config';
import { useTranslation } from 'react-i18next';
import { Select, message, Tabs, Button, Upload, Input, Modal } from 'antd';
import withGuideAccess from './withGuideAccess';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
const MyAccountGuide = () => {
    const confirm = Modal.confirm;
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [lastName, setLastName] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [buildingNumber, setBuildingNumber] = useState('');
    const [street, setStreet] = useState('');
    const [city, setCity] = useState('');
    const [postCode, setPostCode] = useState('');
    const [phoneNumber, setPhoneNumber] = useState(0);
    const [id, setId] = useState(0);
    const [licenseNumber, setLicenseNumber] = useState('');
    const [countryId, setCountry] = useState(0);
    const [countryDataSource, setCountryDataSource] = useState([]);
    const [stripeAccountInfoUrl, setStripeAccountInfoUrl] = useState('');
    const [previewAccountUrl, setPreviewAccountUrl] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [verification, setVerification] = useState(0);
    const [paymentType, setPaymentType] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [taxNumber, setTaxNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [aboutMe, setAboutMe] = useState('');
    const { TextArea } = Input;
    const [verifyDocumentStripe, setVerifyDocumentStripe] = useState(false);
    const [showStripeInfo, setShowStripeInfo] = useState(false);

    useEffect(() => {
        setVerifyDocumentStripe(false);
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            let result = await axios.get(API_URL + 'api/dictionary/countries');

            setCountryDataSource(result.data);

            let resultAccountData = await axios.get(API_URL + 'api/guide', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                },
            });
            setFirstName(resultAccountData.data.firstName);
            setLastName(resultAccountData.data.lastName);
            setBuildingNumber(resultAccountData.data.buildingNumber);
            setCity(resultAccountData.data.city);
            setStreet(resultAccountData.data.street);
            setEmail(resultAccountData.data.email);
            setLicenseNumber(resultAccountData.data.licenseNumber);
            setPhoneNumber(resultAccountData.data.phoneNumber);
            setPostCode(resultAccountData.data.postCode);
            setCountry(resultAccountData.data.countryId);
            setCompanyName(resultAccountData.data.companyName);
            setTaxNumber(resultAccountData.data.taxNumber);
            setStripeAccountInfoUrl(resultAccountData.data.stripeAccountInfoUrl);
            setVerifyDocumentStripe(resultAccountData.data.verifyDocumentStripe);
            setVerification(resultAccountData.data.verification);
            setPaymentType(resultAccountData.data.paymentType);
            setAboutMe(resultAccountData.data.aboutMe);
            setPreviewAccountUrl(resultAccountData.data.urlPreviewParam);
            setId(resultAccountData.data.id);
            let url = `${API_URL}images/guide/${resultAccountData.data.id}/thumb/thumb_${resultAccountData.data.imageUrl}`;
            setImageUrl(url);
            if (resultAccountData.data.verification === 2) {
                localStorage.setItem('fullyVerified', 'true');
            }
            else {
                localStorage.setItem('fullyVerified', 'false');
                // setShowStripeInfo(true);
            }
        } catch (ex) {
            message.error(t("message.error"));
        }

    }
    const uploadImage = async options => {
        const { onSuccess, onError, file } = options;

        const fmData = new FormData();
        const config = {
            headers: { "content-type": "multipart/form-data", "Authorization": `Bearer ${localStorage.getItem('accessToken')}` }

        };
        fmData.append("file", file);
        try {
            const res = await axios.post(
                API_URL + 'api/Guide/image',
                fmData,
                config
            );
            let url = `${API_URL}images/guide/${id}/thumb/thumb_${file.name}`;
            setImageUrl(url);
            onSuccess("Ok");
            console.log("server res: ", res);
        } catch (err) {
            console.log("Eroor: ", err);
            const error = new Error("Some error");
            onError({ err });
        }
    };
    const handleChangePayments = (value) => {
        if(verification === 2){
            setPaymentType(value);

        }else{
            setShowStripeInfo(true);  

        }

    }
    const reloadStripeData = () => {
        fetchData();

    }
    const handleChange = (value) => {
        setCountry(value);
    }
    const handleSubmitAccount = async (e) => {
        e.preventDefault();

        const data = {
            stripeAccountInfoUrl,
            paymentType
        };
        try {
            await axios.patch(API_URL + 'api/Guide/bankaccount', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                },
            }).then(response => {

                message.success(t("message.saveOk"));

            })


        } catch (error) {
            if (error.response.data === "PaymentType : InvalidData") {
                message.error(t("guideAccount.paymentAllError"));

            } else
                message.error(t("message.error"));
        }



    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (errorMessage.length > 0)
            return;
        if (countryId === 0) {
            message.error(t("message.Pleaseselectcountry"));
            return;
        }

        const data = {
            firstName,
            lastName,
            email,
            street,
            city,
            postCode,
            buildingNumber,
            licenseNumber,
            countryId,
            phoneNumber,
            companyName,
            taxNumber,
            aboutMe
        };

        try {
            setIsSubmitting(true);
            const response = await ApiClient('PATCH', 'api/guide', data);

            if (response.ok) {

                message.success(t("message.saveOk"));
            } else {
                message.error(t("message.error"));
            }
        } catch (error) {
            message.error(t("message.error"));
        } finally {

            setIsSubmitting(false);
        }
    };
    const validatePhoneNumber = () => {
        const phoneNumberRegex = /^(\+\d+|\+\d+(?:\s\d+)+)$/;

        if (phoneNumberRegex.test(phoneNumber)) {
            setErrorMessage('');

        } else {
            setErrorMessage(t("message.wrongPhoneNumber"));
        }
    };
    const handleTextAreaChange = (e) => {
        const text = e.target.value;
        const html = text.replace(/\n/g, '<br>');
        setAboutMe(html);
    };
    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );
    const deleteAccount = () => {
        confirm({
            title: t("guideAccount.areYouSureToDeleteAccount"),
            content: t("guideAccount.infoDeleteAccount"),
            okText: t("common.yes"),
            okType: 'danger',
            cancelText: t("common.no"),
            onOk() {
                try {
                    axios.delete(API_URL + 'api/Guide/delete', {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                        },
                    }).then(response => {
                        localStorage.setItem('accessToken', "");
                        localStorage.setItem('expirationDate', "");
                        localStorage.setItem('fullyVerified', "");
                        window.location.href = '/login';

                    }).catch(error => {
                        if (error.response.data === 'ApplicationUser : CancelYourTours') {
                            message.error(t("guideAccount.toursCountDeleteAccount"));
                        } else {
                            message.error(t("message.error"));

                        }
                    })


                } catch (error) {
                    if (error.response.data === "PaymentType : InvalidData") {
                        message.error(t("guideAccount.paymentAllError"));

                    } else
                        message.error(t("message.error"));
                }
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }
    const items = [
        {
            key: '1',
            label: t("guideAccount.tab1Title"),
            children:
                <div>
                    <form className="form-container" onSubmit={handleSubmit} style={{ maxWidth: "800px", minHeight: "1000px" }}>

                        <div className="flex flex-col sm:flex-row flex-wrap gap-10 justify-center">
                            <div style={{ float: "right" }}>
                                <Upload
                                    name="avatar"
                                    listType="picture-card"

                                    showUploadList={false}
                                    customRequest={uploadImage}  >
                                    {imageUrl ? (
                                        <img
                                            src={imageUrl}
                                            alt="avatar"
                                            style={{
                                                color: "#000 !important", margin: "auto"
                                            }}
                                        />
                                    ) : (
                                        uploadButton
                                    )}
                                </Upload></div>
                            <div style={{ textAlign: "center" }}> {t("guideAccount.accountPreview")} <br /><a href={"guide/" + previewAccountUrl}> {window.location.origin + '/guide/' + previewAccountUrl}</a></div>


                        </div>
                        <div>
                            <label>{t("guideAccount.aboutMe")}</label>
                            <TextArea value={aboutMe} onChange={handleTextAreaChange} name='aboutMe' style={{ height: "100px" }} />
                        </div>
                        <div>
                            <label>{t("login.firstName")}:*</label>
                            <input
                                type="text"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label>{t("login.lastName")}:*</label>
                            <input
                                type="text"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label>{t("login.companyName")}:</label>
                            <input
                                type="text"
                                value={companyName}
                                onChange={(e) => setCompanyName(e.target.value)}
                            />
                        </div>
                        <div>
                            <label>{t("login.taxnumber")}*:</label>
                            <input
                                type="text"
                                value={taxNumber}
                                onChange={(e) => setTaxNumber(e.target.value)}
                                required

                            />
                        </div>
                        <div>
                            <label>Email:*</label>
                            <input
                                disabled={true}
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label>{t("login.phoneWithPrefix")}:*</label>

                            <input
                                type="phonenumber"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                onBlur={validatePhoneNumber}
                                required
                            />
                            <p style={{ color: 'red' }}>{errorMessage}</p>
                        </div>
                        <div>
                            <label>{t("login.licenceNumber")}:</label>
                            <input
                                type="text"
                                value={licenseNumber}
                                onChange={(e) => setLicenseNumber(e.target.value)}

                            />
                        </div>
                        <div>
                            <label>{t("login.street")}:*</label>
                            <input
                                type="text"
                                value={street}
                                onChange={(e) => setStreet(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label>{t("login.buildingNumber")}:*</label>
                            <input
                                type="text"
                                value={buildingNumber}
                                onChange={(e) => setBuildingNumber(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label>{t("login.city")}:*</label>
                            <input
                                type="text"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label>{t("login.postCode")}:*</label>
                            <input
                                type="text"
                                value={postCode}
                                onChange={(e) => setPostCode(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label>{t("login.country")}:*</label>
                            <Select
                                value={countryId}
                                style={{ width: 120 }}
                                onChange={handleChange}
                                options={countryDataSource}
                                required
                            />
                        </div>
                        <div><button style={{ width: "100px" }} type="submit" disabled={isSubmitting}>{isSubmitting ? t("login.inProgres") : t("common.save")} </button></div>
                        <div><Button style={{ width: "100px", height: "40px", backgroundColor: "red", color: "white" }} onClick={deleteAccount} >
                            {t("guideAccount.delete")}
                        </Button></div>
                    </form>

                </div>



        },
        {
            key: '2',
            label: t("guideAccount.tab2Title"),
            children:
                <form className="form-container" onSubmit={handleSubmitAccount} style={{ maxWidth: "800px", minHeight: "500px" }}>

                    <div>
                        <Row>
                            <Col>
                                Stripe Dashboard <a href='https://connect.stripe.com/express_login' target="_blank"> :  Link</a>


                                <label >{t("guideAccount.line2")}</label>
                                <label> {t("guideAccount.line3")}</label>
                                <label>{t("guideAccount.line4")} </label>
                                <label style={{ color: "red" }} hidden={verification === 2}>  {t("guideAccount.guideAccountNotVerifeid")}  </label>
                                <label style={{ color: "red" }} hidden={verifyDocumentStripe === false}>  {t("guideAccount.verifyDocumentStripe")}  </label>

                                <label style={{ margin: "auto", color: "blue" }}><a href={stripeAccountInfoUrl} onClick={reloadStripeData} target='_blank' hidden={verification === 2 && verifyDocumentStripe === false}>{t("guideAccount.goToStripe")}</a></label>
                                {/* <a style={{ textAlign: "center" }} href={stripeAccountInfoUrl} target='_blank' hidden={verification === 2}>{stripeAccountInfoUrl}</a> */}
                                <label> {t("guideAccount.line5")}</label>
                                <label>{t("guideAccount.line6")}</label>

                                <Select
                                    value={paymentType}
                                    style={{ minWidth: "100%" }}
                                    onChange={handleChangePayments}
                                    required                                    
                                    options={[
                                        { value: 1, label: t("guideAccount.value1") },
                                        { value: 2, label: t("guideAccount.value2") },
                                    ]}
                                />
                            </Col>
                        </Row>

                    </div>
                    <button style={{ width: "100px" }} type="submit" disabled={isSubmitting}>{isSubmitting ? t("login.inProgres") : t("common.save")} </button>

                </form>
        },
        {
            key: '3',
            label: t("guideAccount.tab3Title"),
            children:
                <div>
                    {t("guideAccount.guideInstruction")} <a href='Instrukcja obsługi Organizator.pdf' target="_blank">[{t("common.download")}] </a> <br />

                </div>

        }
    ];
    return (
        <Container style={{ margin: "auto" }}>

            <Row style={{ paddingRight: "1.5rem", paddingLeft: "1.5rem" }}>

                <Tabs defaultActiveKey="1" items={items} />
            </Row>
            <Modal open={showStripeInfo} onCancel={() => setShowStripeInfo(false)} footer={null} >
                <Row >
                    <Col className="mx-auto">
                        
                        <p>Aby zmienić sposób płatności oraz rozliczeń wymagana jest weryfikacja konta w portalu <strong><a href='https://stripe.com' target='_blank'>Stripe</a></strong><br /><br />Stripe jest jednym z największych operatorów płatności na świecie, z którego korzystają takie firmy jak Amazon, Google, Zara, BMW, a teraz również TourPilot! Dzięki integracji ze Stripe możemy zagwarantować bezpieczne i bezproblemowe rozliczenia.</p>
                        <br />
                        <p><em>Prosimy pamiętać, że jako tourpilot.eu nie mamy dostępu do Twoich danych osobowych , w tym dokumentów tożsamości , przekazanych dla Stripe. Wszystkie informacje przekazane podczas procesu weryfikacji w Stripe są zarządzane wyłącznie przez Stripe.com, zgodnie z ich polityką prywatności.</em></p>                        <br />
                        <br /><p style={{ margin: "auto", textAlign: "center" }}><strong><a href={stripeAccountInfoUrl} onClick={reloadStripeData} target='_blank' hidden={verification === 2 && verifyDocumentStripe === false}>{t("guideAccount.goToStripe")}</a></strong></p>
                        <br />
                        <p><em>W razie jakichkolwiek problemów z weryfikacją , zapraszamy do kontaktu : office@tourpilot.eu</em></p>


                    </Col>
                </Row>
            </Modal>
        </Container>

    );
};

export default withGuideAccess(MyAccountGuide);
