import React, { useState, useEffect } from 'react';
import '../GuideAddTourForm.css';
import { Button, Form, Input, InputNumber, message, Upload, Select, Checkbox, Radio } from 'antd';
import LanguageInput from "./LanguageInput.js";
import LanguageTextArea from "./LanguageTextArea.js";
import LanguageSelector from "./LanguageSelector.js";
import LocationAutosuggest from "./LocationAutosuggest.js";
import PriceComponent from "./PriceComponent.js";
import { Row, Col } from 'react-bootstrap';
import ApiClient from './ApiClient';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import API_URL from '../config';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useNavigate } from 'react-router-dom';


  
const GuideEditTourForm = () => {
    const navigate = useNavigate();
    const [location, setLocation] = useState({});
    const [errors, setErrors] = useState({});
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState([]);
    const [progress, setProgress] = useState(0);
 
    const [isActive, setIsActive] = useState({});
    const { t } = useTranslation();
    const { id } = useParams();
    const [tourDefinition, setTourDefinition] = useState({});
    const [perPerson, setPerPerson] = useState(false);
    const [pricePersons, setPricePersons] = useState([]);
    const [pricePerAll, setPricePerAll] = useState(0);
    const [selectedLanguages, setSelectedLanguages] = useState([]);
   

    useEffect(() => {
        if (id) {
            // fetchLanguageData();
            fetchData();
        }
    }, []);


    useEffect(() => {
        console.log('tourDefinition changed:', tourDefinition);
        if (tourDefinition && tourDefinition.id) {
            var googleLocation = {
                label: tourDefinition.region,
                location: {
                    lat: tourDefinition.lat,
                    lng: tourDefinition.lng,
                },
                place_id: tourDefinition.placeId
            }
            tourDefinition.description = tourDefinition.description;
            // setLanguage(tourDefinition.languageId);
            form.setFieldsValue({
                Title: tourDefinition.title,
                Description: tourDefinition.description,
                DescriptionShort: tourDefinition.descriptionShort,
                MaxCapacity: tourDefinition.maxCapacity,
                TourLenght: tourDefinition.tourLenght,
                MeetingPoint: tourDefinition.meetingPoint,
                IsActive: tourDefinition.isActive
                // Currency: tourDefinition.currency
            });
            setLocation(googleLocation);
            setIsActive(tourDefinition.isActive);
            setPerPerson(tourDefinition.perPerson);
            setPricePersons(tourDefinition.perPersonPriceJson);
            setPricePerAll(tourDefinition.price);
            const images = tourDefinition.images ? JSON.parse(tourDefinition.images) : [];
            const existingFileList = Array.isArray(images)
                ? images.map((image) => ({
                    uid: image,
                    name: image,
                    status: 'done',
                }))
                : [];
            setFileList(existingFileList);
            let initialLanguages =JSON.parse(tourDefinition.languages);
            setSelectedLanguages(initialLanguages);
            form.setFieldsValue({ language: initialLanguages });
        }

    }, [tourDefinition]);


    const uploadImage = async options => {
        const { onSuccess, onError, file, onProgress } = options;

        const fmData = new FormData();
        const config = {
            headers: { "content-type": "multipart/form-data", "Authorization": `Bearer ${localStorage.getItem('accessToken')}` },
            onUploadProgress: event => {
                const percent = Math.floor((event.loaded / event.total) * 100);
                setProgress(percent);
                if (percent === 100) {
                    setTimeout(() => setProgress(0), 1000);
                }
                onProgress({ percent: (event.loaded / event.total) * 100 });
            }
        };
        fmData.append("attachments", file);
        try {
            const res = await axios.post(
                API_URL + 'api/tourdefinitions/' + tourDefinition.id + '/image',
                fmData,
                config
            );

            onSuccess("Ok");
            console.log("server res: ", res);
        } catch (err) {
            console.log("Eroor: ", err);
            const error = new Error("Some error");
            onError({ err });
        }
    };
    const onFileChange = ({ fileList }) => {
        setFileList(fileList);
    };

    const props = {
        onRemove: async (file) => {
            const index = fileList.indexOf(file);
            const response = await ApiClient('DELETE', 'api/tourdefinitions/' + tourDefinition.id + '/image?filename=' + file.name, null);
            if (response.ok) {
                message.success(t("message.deleted"));
                const newFileList = fileList.slice();
                newFileList.splice(index, 1);
                setFileList(newFileList);
                return true;
            } else {
                message.error(t("message.error"));
                return false;
            }
        },
        beforeUpload: (file) => {
            const isPNG = file.type === 'image/png';
            const isJPG = file.type === 'image/jpeg';
            if (!isPNG && !isJPG) {
                message.error(`${file.name} is not a png or jpg file`);
            }
            return isPNG || isJPG || Upload.LIST_IGNORE;
        },
        fileList,
    };
    const onFinish = async (values) => {
        console.log('Form values:', values);
        // if (languageId <= 0) {
        //     message.error(t("guideTourFrom.selectLanguage"));
        // }
        if (!form) {
            console.error('Form is not defined');
            return;
        }
        const validationErrors = {};

        errors.location = '';
        if (location.place_id.length === 0) {
            validationErrors.location = t("guideTourFrom.selectLocation");

        }

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
       
        const data = {
            id: id,
            title: isJSON(values.Title) ? values.Title : JSON.stringify(values.Title) ,
            description: isJSON(values.Description) ? values.Description : JSON.stringify(values.Description)  ,
            region: location.label,
            lat: location.location.lat, // Konwersja na string
            lng: location.location.lng, // Konwersja na string      
            placeId: location.place_id,
            descriptionShort: isJSON(values.DescriptionShort) ?  values.DescriptionShort : JSON.stringify(values.DescriptionShort),
            price: pricePerAll,
            currency: tourDefinition.currency,
            maxCapacity: values.MaxCapacity,
            // languageId: languageId,
            languages: isJSON(selectedLanguages) ? selectedLanguages : JSON.stringify(selectedLanguages)  , 
            IsActive: isActive,
            tourLenght: values.TourLenght,
            meetingPoint:  isJSON(values.MeetingPoint) ?values.MeetingPoint  : JSON.stringify(values.MeetingPoint) ,
            perPerson: perPerson,
            perPersonPriceJson: pricePersons
        };

        try {
          
            const response = await ApiClient('PUT', 'api/tourdefinitions', data);

            if (response.ok) {
                message.success(t("message.saveOk"));

            } else {
                message.error(t("message.error"));
            }
        } catch (error) {
            console.error('Error:', error);
            message.error(t("message.error"));
        } finally {
            // Enable the button after the form submission is complete
        }
        setErrors({});

    };
    const fetchData = async () => {

        try {

            const result = await axios.get(API_URL + 'api/tourdefinitions/' + id, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                },
            });
            setTourDefinition(result.data, () => {
                console.log('Callback after setTourDefinition:', tourDefinition);
                // Rest of your code here...
            });


        } catch (e) {
            message.error(t("message.error"));
        }
    }
    // const fetchLanguageData = async () => {
    //     let result = await axios.get(API_URL + 'api/dictionary/languages');
    //     setLanguagesDataSource(result.data);
    // }

    const handleLanguageChange = (value) => {
        setSelectedLanguages(JSON.stringify(value));
    }

    const handleLocationChange = (value) => {
        setLocation(value);
        errors.location = "";
    };
    const handlePriceChange = (value) => {

        setPricePersons(JSON.stringify(value));
    };
    const handlePriceAllChange = (value) => {

        setPricePerAll(value);

    };
    const handleIsActiveChange = (e) => {

        setIsActive(e.target.checked);
    };
    const goBack = () => {
        navigate('/guide/list');
    }
    const handlePerPerson = (e) => {

        setPerPerson(e.target.value);
    };
    const isJSON = (value) => {
        try {
          const parsed = JSON.parse(value);          
          return typeof parsed === 'object' && parsed !== null;
        } catch (e) {
          return false;
        }
      };
    return (

        <Form form={form} onFinish={onFinish} layout="vertical">
            <div style={{ marginTop: "10px", marginBottom: "10px" }} >
                <Button onClick={goBack} type="primary">
                    {t("common.goBack")}
                </Button></div>
            <Row gutter={[16, 0]}>

                <Col span={12}>
                    <Form.Item name="Title" label={t("guideTourFrom.title")} rules={[{ required: true, message: 'Title is required' }]} initialValue={tourDefinition.title}>
                            <LanguageInput  />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="location" label={t("guideTourFrom.location")}>
                        <LocationAutosuggest onLocationChange={handleLocationChange} location={location} />
                        {errors.location && (
                            <span className="error-message">{errors.location}</span>
                        )}
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 0]}>
                <Col span={12}>
                    <Form.Item name="DescriptionShort" label={t("guideTourFrom.descriptionShort")} rules={[{ required: true }]} initialValue={tourDefinition.descriptionShort} >
                    <LanguageInput />
                    </Form.Item>
                    <Form.Item name="MeetingPoint" label={t("guideTourFrom.meetingPoint")} rules={[{ required: true }]} initialValue={tourDefinition.meetingPoint}>
                    <LanguageInput />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 0]}>
                <Col span={12}>
                    <Form.Item name="Description" label={t("guideTourFrom.descriptionFull")} rules={[{ required: true }]} initialValue={tourDefinition.description}>
                    <LanguageTextArea rows={4}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row >

                <Col >
                    <Form.Item name="language" label={t("common.language")}>
                    <LanguageSelector value={selectedLanguages}  onChange={handleLanguageChange}/>
     </Form.Item>
                    {/* <Form.Item name="Currency" label={t("common.currency")} rules={[{ required: true }]}>
                        <Select
                            style={{ width: 120 }}
                            options={[
                                // {
                                //     value: 'EUR',
                                //     label: 'EUR',
                                // },
                                {
                                    value: 'PLN',
                                    label: 'PLN',
                                },
                            ]}
                        />
                    </Form.Item> */}
                    <Form.Item name="Price" label={t("guideTourList.price") + " " + tourDefinition.currency} >

                        <Radio.Group onChange={handlePerPerson} value={perPerson}>
                            <Radio value={false}>{t('guideTourFrom.pricePerAtraction')}</Radio>
                            <Radio value={true}>{t('guideTourFrom.pricePerPerson')}</Radio>
                        </Radio.Group>
                        <div hidden={perPerson}><InputNumber onChange={handlePriceAllChange} value={pricePerAll} min={0} /></div>
                        <div hidden={!perPerson}> <PriceComponent onPriceChange={handlePriceChange} dataSourceToSet={pricePersons} /> </div>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            {t("common.save")}
                        </Button>
                    </Form.Item>

                </Col>
                <Col >

                    <Form.Item name="MaxCapacity" label={t("guideTourList.maxcapacity")} rules={[{ required: true }]}>
                        <InputNumber min={0} />
                    </Form.Item>
                    <Form.Item name="IsActive" label={t("guideTourList.isActive")} rules={[{ required: true }]}>
                        <Checkbox checked={isActive} onChange={handleIsActiveChange}></Checkbox>
                    </Form.Item>
                    <Form.Item name="TourLenght" label={t("guideTourList.tourLenght")} rules={[{ required: true }]}>
                        <InputNumber min={0} step="0.5" />
                    </Form.Item>
                </Col>
                <Col >
                    <Form.Item name="attachments" label={t("guideTourList.attachments")}>
                        <Upload {...props} onChange={onFileChange} customRequest={uploadImage} >
                            <Button icon={<UploadOutlined />}>{t("guideTourList.selectFiles")}</Button>
                        </Upload>
                    </Form.Item>

                </Col>
            </Row>



        </Form>

    );
};

export default GuideEditTourForm;
